import { Component, OnInit } from '@angular/core';
import { Organization } from 'src/app/_interface/organization';
import { MenuSubService } from 'src/app/_services/menu-sub.service';
import { OrganizationService } from 'src/app/_services/organization.service';
import { ConfirmationService, FilterService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ORGANIZATION_MENU_OPTIONS } from 'src/app/pages/organization/organization-menu/organization-config';

@Component({
  selector: 'app-organization-list-staff',
  templateUrl: './organization-list-staff.component.html',
  styleUrls: ['./organization-list-staff.component.scss'],
  providers: [FilterService]
})
export class OrganizationListStaffComponent implements OnInit {
  SUBJECT: string = 'organization';
  SUBJECT_PLURAL: string = 'organizations';
  errorMessage: string | null = null;
  filteredOrganizations: Organization[] = [];
  filters: any = {};
  isLoading: boolean = true;
  pagedOrganizations: Organization[] = [];
  organizations: Organization[] = [];

  // Paginator properties
  first: number = 0;
  rows: number = 10;

  // This property is retained for possible use in a table (e.g., with Angular Material)
  displayedColumns: string[] = ['name', 'email', 'phone_number', 'actions'];

  constructor(
    private menuSubService: MenuSubService,
    private organizationService: OrganizationService,
    private filterService: FilterService,
    private snackBar: MatSnackBar,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    // Update the submenu options
    this.menuSubService.updateSubMenuOptions(ORGANIZATION_MENU_OPTIONS);
    this.loadOrganizations();
  }

  loadOrganizations(): void {
    this.organizationService.OrganizationListStaff().subscribe({
      next: (data: Organization[]) => {
        this.organizations = data;
        this.filteredOrganizations = data;
        this.updatePagedOrganizations();
        this.isLoading = false;
      },
      error: (error: any) => {
        this.errorMessage = 'Error loading organizations';
        this.isLoading = false;
        console.error('Error loading organizations', error);
      }
    });
  }

  applyFilter(field: keyof Organization, value: string): void {
    // Update the filters object
    this.filters[field] = value;

    // Start with the complete organizations list
    let filtered = this.organizations;

    // Apply all filters that have a value
    for (const key in this.filters) {
      if (this.filters[key]) {
        filtered = this.filterService.filter(filtered, [key], this.filters[key], 'contains');
      }
    }

    this.filteredOrganizations = filtered;
    // Reset pagination to the first page when filters change.
    this.first = 0;
    this.updatePagedOrganizations();
  }

    deleteOrganization(organizationId: string): void {
        this.confirmationService.confirm({
            message: `Are you sure you want to delete this organization? This action cannot be undone.`,
            header: 'Confirm Deletion',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.organizationService.OrganizationDelete(organizationId).subscribe({
                    next: () => {
                        // Remove the deleted organization from the arrays
                        this.organizations = this.organizations.filter(org => org.id !== organizationId);
                        this.filteredOrganizations = this.filteredOrganizations.filter(org => org.id !== organizationId);
                        this.updatePagedOrganizations();
                        this.snackBar.open('Organization deleted successfully', 'Close', {
                          duration: 3000,
                          panelClass: ['snackbar-success']
                        });
                    },
                    error: (error: any) => {
                        console.error('Error deleting organization', error);
                        this.snackBar.open('Failed to delete organization', 'Close', {
                          duration: 3000,
                          panelClass: ['snackbar-error']
                        });
                    }
                });
            },
            reject: () => {
                // No action needed on rejection.
            }
        });
    }

  onFilterInput(field: keyof Organization, event: Event): void {
    let value = '';
    if (
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLSelectElement
    ) {
      value = event.target.value;
    }
    this.applyFilter(field, value);
  }

  onPageChange(event: any): void {
    this.first = event.first;
    this.rows = event.rows;
    this.updatePagedOrganizations();
  }

    openWebsite(url: string): void {
        window.open(url, '_blank');
    }
  
  updatePagedOrganizations(): void {
    this.pagedOrganizations = this.filteredOrganizations.slice(this.first, this.first + this.rows);
  }

}
