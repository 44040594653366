import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Media } from 'src/app/_interface/media';
import { environment } from 'src/environments/environment.prod';

@Injectable({ providedIn: 'root' })
export class MediaService {
    private baseUrl = `${environment.apiBaseUrl}media/`;

    constructor(private http: HttpClient) {}

    getAll(): Observable<Media[]> {
        return this.http.get<Media[]>(this.baseUrl);
    }

    get(id: string): Observable<Media> {
        return this.http.get<Media>(`${this.baseUrl}${id}/`);
    }

    create(media: Partial<Media>): Observable<Media> {
        return this.http.post<Media>(`${this.baseUrl}create/`, media);
    }

    update(id: string, media: Partial<Media>): Observable<Media> {
        return this.http.put<Media>(`${this.baseUrl}${id}/edit/`, media);
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}${id}/delete/`);
    }

    upload(file: File): Observable<Media> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        // Adjust the endpoint as needed; for example, if your backend accepts file uploads at 'media/create/'
        return this.http.post<Media>(`${this.baseUrl}create/`, formData);
    }
}
