// src/app/shared/google-maps.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  /**
   * Opens a new window/tab with Google Maps searching for the provided address.
   * @param address An object containing address properties.
   */
  openAddressInGoogleMaps(address: { street?: string; city?: any; state?: any; postal_code?: string }): void {
    const street = address.street || '';
    const city = address.city && typeof address.city === 'object' ? address.city.name : address.city || '';
    const state = address.state && typeof address.state === 'object' ? address.state.name : address.state || '';
    const postal = address.postal_code || '';
    const fullAddress = `${street}, ${city}, ${state} ${postal}`;
    const encodedAddress = encodeURIComponent(fullAddress);
    const url = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(url, '_blank');
  }
}
