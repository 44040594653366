import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { AvatarGroupModule } from 'primeng/avatargroup';
import { AvatarModule } from 'primeng/avatar';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';

import { BodyComponent } from './body/body.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NavComponent } from './menu/nav/nav.component';
import { NavFooterComponent } from './menu/nav-footer/nav-footer.component';


@NgModule({
  declarations: [
    BodyComponent,
    FooterComponent,
    HeaderComponent,
    NavComponent,
    NavFooterComponent,
  ],
  imports: [
    AvatarGroupModule,
    AvatarModule,
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MenubarModule,    
    RouterModule,
    ToastModule
  ],
  exports: [
    BodyComponent,
    FooterComponent,
    HeaderComponent,
    NavComponent,
    NavFooterComponent,
  ]
})
export class LayoutModule { }
