/* src/app/pages/event/event-list-public/event-list-public.component.ts */
import { Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { City } from 'src/app/_interface/city';
import { Event } from 'src/app/_interface/event';
import { State } from 'src/app/_interface/state';
import { EventService } from 'src/app/_services/event.service';
import { MenuSubService } from 'src/app/_services/menu-sub.service';
import { EVENT_MENU_OPTIONS } from 'src/app/pages/event/event-menu/event-config';

@Component({
  selector: 'app-event-list-public',
  templateUrl: './event-list-public.component.html',
  styleUrls: ['./event-list-public.component.css']
})
export class EventListPublicComponent {
  // Full list of events (if needed).
  events: Event[] = [];
  text: string = 'Event List Public';

  /**
   * Events returned from the range-search endpoint.
   */
  rangeEvents: Event[] = [];

  /**
   * The currently selected date from the calendar.
   * This is initialized to today's date.
   */
  selectedDate: Date = new Date();

  // Properties for PrimeNG p-dialog share modal
  displayShareDialog: boolean = false;
  shareUrl: string = '';

  constructor(
    private menuSubService: MenuSubService,
    private eventService: EventService,
    private clipboard: Clipboard
  ) {}

  /**
   * Lifecycle hook called after component data-bound properties are initialized.
   * It updates the submenu options and immediately issues a range search using today's
   * date (formatted as "YYYY-MM-DD") with a 14-day range.
   */
  ngOnInit(): void {
    // Update the submenu options.
    this.menuSubService.updateSubMenuOptions(EVENT_MENU_OPTIONS);

    // Immediately call the range search with today's date and a range of 14 days.
    this.fetchRangeEvents(this.selectedDate);      
  }

  /**
   * Handler for when a date is selected from the calendar.
   * It issues a POST request to the range-search endpoint using the selected date.
   *
   * @param event The event data from the p-calendar component (expected to be a Date object).
   */
  onDateSelect(event: any): void {
    const selected: Date = event;
    this.selectedDate = selected;
    this.fetchRangeEvents(selected);
  }

  /**
   * Copies the provided URL to the clipboard.
   *
   * @param value The URL string to copy.
   */
  copyUrl(value: string): void {
    this.clipboard.copy(value);
    // Optionally, add a message or visual cue indicating success.
  }

  /**
   * Fetch events using the range search endpoint based on the provided date.
   *
   * @param date The starting date for the range search.
   */
  private fetchRangeEvents(date: Date): void {
    const formattedDate = this.formatDate(date);
    this.eventService.getRange({ start_date: formattedDate, days: 14 }).subscribe({
      next: (data: Event[]) => {
        this.rangeEvents = data.map(event => ({
          ...event,
          isExpanded: false
        }));
        console.log('Range events:', data);
      },
      error: (error) => {
        console.error('Error fetching range events:', error);
      }
    });
  }

  /**
   * Formats a Date object to a string in "YYYY-MM-DD" format.
   *
   * @param date The Date object to format.
   * @returns A string representing the date in "YYYY-MM-DD" format.
   */
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
   * Returns the city name based on the given city object or string.
   *
   * @param city The city object or string.
   * @returns The city name or empty string if not available.
   */
  getCityName(city: City | string | null | undefined): string {
    if (!city) return '';
    return typeof city === 'object' ? city.name : '';
  }
  
  /**
   * Returns the state name based on the given state object or string.
   *
   * @param state The state object or string.
   * @returns The state name or empty string if not available.
   */
    getStateCode(state: State | string | null | undefined): string {
        if (!state) return '';
        return typeof state === 'object' ? state.code : '';
    }

    getStateName(state: State | string | null | undefined): string {
        if (!state) return '';
        return typeof state === 'object' ? state.name : '';
    }

  /**
   * Shows the share dialog and sets the share URL based on the event's id.
   *
   * @param event The event object.
   */
  openShareDialog(event: Event): void {
    this.shareUrl = `${window.location.origin}/detail/${event.id}`;
    this.displayShareDialog = true;
  }

    /**
     * Opens Google Maps with the event's address details.
     *
     * @param event The event object containing the address.
     */
    openInGoogleMaps(event: Event): void {
        const street = event.address?.street || '';
        const city = this.getCityName(event.address?.city);
        const state = this.getStateName(event.address?.state);
        const postal = event.address?.postal_code || '';
        // Construct a full address string
        const fullAddress = `${street}, ${city}, ${state} ${postal}`;
        const encodedAddress = encodeURIComponent(fullAddress);
        const url = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
        window.open(url, '_blank');
    }

    openWebsite(url: string): void {
        if (url) {
            // Ensure the URL starts with http:// or https://. If not, default to https://.
            const normalizedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
            window.open(normalizedUrl, '_blank');
        }
    }
  
}
