<h2 mat-dialog-title>Share Event</h2>
<mat-dialog-content>
  <p>Copy the link below to share this event:</p>
  <mat-form-field appearance="fill" style="width: 100%;">
    <input matInput [value]="shareUrl" readonly>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="copyUrl()">Copy URL</button>
  <button mat-button (click)="close()">Close</button>
</mat-dialog-actions>
