// today/src/app/layout/menu/nav/nav.component.ts
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { MenuService } from 'src/app/_services/menu.service';
import { MenuItem } from 'primeng/api';


interface NavItem {
    text: string;
    link?: string;
    icon?: string;
    children?: NavItem[];
    action?: () => void;
    requiresStaff?: boolean;
}

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {
    menu: NavItem[] = [];
    items: MenuItem[] = [];
    isMenuVisible: boolean = false;
    private menuSubscription: Subscription = new Subscription();
    selectedMenuItem: string = '';
    isAuthenticated: boolean = false;
    activeItem: NavItem | null = null;
    userIsStaff: boolean = false;

    constructor(
        private authService: AuthService,
        private menuService: MenuService
    ) {
        this.menuSubscription = new Subscription();
    }

    ngOnInit(): void {
        this.items = [
            {
                label: 'events',
                icon: 'pi pi-fw pi-calendar',
                items: [
                  { label: 'List', icon: 'pi pi-fw pi-list', routerLink: '/event/list' },
                  { label: 'Add', icon: 'pi pi-fw pi-plus', routerLink: '/event/add' }
                ]
              },            
            { label: 'login', icon: 'pi pi-fw pi-home', routerLink: '/login' },
        ];
        this.menuSubscription = this.menuService.getMenuVisible().subscribe(visible => {
            this.isMenuVisible = visible;
        });

        // Subscribe to the authentication status
        this.authService.isAuthenticated().subscribe(isAuth => {
            this.isAuthenticated = isAuth;
            this.updateMenu(); // Call to update the menu based on authentication status
        });

        // **Subscribe to staff status**:
        this.authService.getUserIsStaff().subscribe(isStaff => {
            this.userIsStaff = isStaff;
            // If you want to dynamically remove or filter items:
            this.updateMenu();
        });
    }

    ngOnDestroy(): void {
        this.menuSubscription.unsubscribe();
    }

    toggleMenu(item?: NavItem): void {
        this.menuService.toggleMenu();
    }
    
    selectMenuItem(item: any): void {
        this.selectedMenuItem = item.link;
    }
        
    updateMenu(): void {
        // Check if we're running locally. You can also check the port if needed.
        const isLocal = window.location.hostname === 'localhost';
        this.items = [
            ...(isLocal ? [
                {
                    label: 'news',
                    icon: 'pi pi-fw pi-list',
                    routerLink: '/news/list-admin',
                },
            ] : []),
            ...(this.userIsStaff ? [
                {
                    label: 'staff',
                    icon: 'pi pi-fw pi-calendar',
                    items: [
                        {
                            label: 'addresses',
                            icon: 'pi pi-fw pi-list',
                            routerLink: '/address/list',
                            Items: [
                                { label: 'add', icon: 'pi pi-fw pi-plus', routerLink: '/address/add' }
                            ]
                        },
                        {
                            label: 'events',
                            icon: 'pi pi-fw pi-list',
                            routerLink: '/event/admin-list',
                            items: [
                                { label: 'add', icon: 'pi pi-fw pi-plus', routerLink: '/event/add' }
                            ]
                        },
                        {
                            label: 'groups',
                            icon: 'pi pi-fw pi-list',
                            routerLink: '/group/list-staff',
                            items: [
                                { label: 'add', icon: 'pi pi-fw pi-plus', routerLink: '/group/add' },
                                { label: 'moderate', icon: 'pi pi-fw pi-list', routerLink: '/group/moderator' }
                            ]

                        },
                        {
                            label: 'organizations',
                            icon: 'pi pi-fw pi-list',
                            routerLink: '/organization/list-staff',
                            items: [
                                { label: 'add', icon: 'pi pi-fw pi-plus', routerLink: '/organization/add' },
                                { label: 'moderate', icon: 'pi pi-fw pi-plus', routerLink: '/organization/moderator' }
                            ]

                        },
                        {
                            label: 'users',
                            icon: 'pi pi-fw pi-list',
                            routerLink: '/user/list-staff',
                            items: [
                                { label: 'moderate', icon: 'pi pi-fw pi-list', routerLink: '/user/moderator' }
                            ]

                        },

                    ]
                }
            ] : []),
            {
                label: 'events',
                icon: 'pi pi-fw pi-calendar',
                routerLink: '/event/list',
            },
            /*
            {
                label: 'local',
                icon: 'pi pi-fw pi-calendar',
                routerLink: '/organization/list',
            },
            */

            // Conditionally add "Admin" menu only if the user is a staff member
            // Conditionally add either the "Account" or "Login" menu item
            ...(this.isAuthenticated ? [
                {
                    label: 'account',
                    icon: 'pi pi-fw pi-user',
                    items: [
                    // Uncomment the profile item if needed:
                    { label: 'profile', icon: 'pi pi-fw pi-id-card', routerLink: '/user/profile/' },
                    { label: 'logout', icon: 'pi pi-fw pi-sign-out', command: () => this.logout() }
                    ]
                }
                ] : [
                    {
                        label: 'sign in/create account',
                        icon: 'pi pi-fw pi-sign-in',
                        routerLink: '/login'
                    }
                ]
            )
        ];
    }          
    
    logout(): void {
        this.authService.logout();
    }    
}
