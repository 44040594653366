import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from 'src/app/_interface/category';
import { Address } from 'src/app/_interface/address';
import { Organization } from 'src/app/_interface/organization';
import { EventService } from 'src/app/_services/event.service';
import { CategoryService } from 'src/app/_services/category.service';
import { OrganizationService } from 'src/app/_services/organization.service';
import { MediaService } from 'src/app/_services/media.service';
import { MessageService } from 'src/app/_services/message.service';

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.css']
})
export class EventEditComponent implements OnInit {
  SUBJECT = 'event';
  ACTION = 'edit';
  eventId: string = '';

  // Using a similar form model as in the add component.
  event: any = {
    address_id: '',
    category_id: '',
    name: '',
    notes: '',
    description: '',
    is_active: true,
    is_public: false,
    status: 'approved',
    is_online: false,
    date_start: '',
    date_end: '',
    organization: '',
    media_primary: '',
    media_primary_id: '',
    website: ''
  };

  categories: Category[] = [];
  organizations: Organization[] = [];
  addresses: Address[] = [];
  selectedFile: File | null = null;
  uploadedMediaUrl: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService,
    private categoryService: CategoryService,
    private organizationService: OrganizationService,
    private mediaService: MediaService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    // Get the event ID from the route (assuming your route is defined with an 'id' parameter)
    this.eventId = this.route.snapshot.paramMap.get('id') || '';
    if (this.eventId) {
      this.loadEvent();
    }
    this.loadCategories();
    this.loadOrganizations();
  }

  loadEvent(): void {
    this.eventService.getEventDetail(this.eventId).subscribe({
      next: (data: any) => {
        this.event = {
          address_id: data.address ? data.address.id : '',
          category_id: data.category ? data.category.id : '',
          name: data.name,
          notes: data.notes,
          description: data.description,
          is_active: data.is_active,
          is_public: data.is_public,
          status: data.status,
          is_online: data.is_online,
          date_start: data.date_start,
          date_end: data.date_end,
          organization_id: data.organization ? data.organization.id : '',
          media_primary: '',
          media_primary_id: data.media_primary ? data.media_primary.id : '',
          website: data.website
        };
  
        if (data.media_primary && data.media_primary.file_url) {
          this.uploadedMediaUrl = data.media_primary.file_url;
        }
  
        // If the event is offline and there is an organization, load addresses immediately.
        if (!this.event.is_online && this.event.organization_id) {
          this.organizationService.OrganizationAddressList(this.event.organization_id).subscribe({
            next: (addresses) => {
              this.addresses = addresses;
            },
            error: (err) => {
              console.error('Error loading addresses for organization:', err);
            }
          });
        }
      },
      error: (err) => {
        console.error('Error loading event details:', err);
      }
    });
  }
  

  loadCategories(): void {
    this.categoryService.listCategories('event').subscribe({
      next: (data: Category[]) => {
        this.categories = data;
      },
      error: (err) => {
        console.error('Error fetching event categories:', err);
      }
    });
  }

  loadOrganizations(): void {
    this.organizationService.OrganizationListPublic().subscribe({
      next: (data: Organization[]) => {
        this.organizations = data;
      },
      error: (err) => {
        console.error('Error loading organizations:', err);
      }
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.selectedFile = input.files[0];
      // Immediately upload the file.
      this.mediaService.upload(this.selectedFile).subscribe({
        next: (media: any) => {
          this.event.media_primary_id = media.id;
          this.uploadedMediaUrl = media.file_url;
          console.log('Media uploaded successfully:', media);
        },
        error: (err) => {
          console.error('Error uploading media:', err);
        }
      });
    }
  }

  onOrgChange(e: Event): void {
    const selectElement = e.target as HTMLSelectElement;
    const orgId = selectElement.value;
    this.event.organization_id = orgId;
    // Load addresses for the selected organization.
    this.organizationService.OrganizationAddressList(orgId).subscribe({
      next: (addresses) => {
        this.addresses = addresses;
      },
      error: (err) => {
        console.error('Error loading addresses for organization:', err);
      }
    });
  }
  
  toggleLocationFields(): void {
    if (this.event.is_online) {
      this.event.address_id = '';
      this.addresses = [];
    }
  }

  updateEvent(): void {
    this.eventService.updateEvent(this.eventId, this.event).subscribe({
      next: (updatedEvent: any) => {
        console.log('Event updated successfully:', updatedEvent);
        this.messageService.sendMessage({
          text: 'Event successfully updated!',
          action: 'Success'
        });
        // Optionally navigate to the event detail page.
        this.router.navigate(['/events', this.eventId]);
      },
      error: (err) => {
        console.error('Error updating event:', err);
        this.messageService.sendMessage({
          text: 'Failed to update event. Please try again.',
          action: 'Error'
        });
      }
    });
  }

  onSubmit(): void {
    this.updateEvent();
  }

  getCityName(city: any): string {
    if (city && typeof city === 'object') {
      return city.name;
    }
    return '';
  }
}
