// today/src/app/pages/event/event.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog';


import { ShareDialogComponent } from 'src/app/_utility/shared/share-dialog/share-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';

import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';  // <-- Important!
import { EditorModule } from 'primeng/editor';

import { StripHtmlPipe } from 'src/app/_utility/strip-html';

import { EventAddComponent } from 'src/app/pages/event/event-add/event-add.component';
import { EventEditComponent } from 'src/app/pages/event/event-edit/event-edit.component';
import { EventListComponent } from 'src/app/pages/event/event-list/event-list.component';
import { EventListPublicComponent } from 'src/app/pages/event/event-list-public/event-list-public.component';
import { EventsRoutingModule } from './event-routing.module';



@NgModule({
    declarations: [
        EventAddComponent,
        EventEditComponent,
        EventListComponent,
        EventListPublicComponent,
        ShareDialogComponent,
        StripHtmlPipe
    ],
    imports: [
        CalendarModule,
        CardModule,
        CommonModule,
        DialogModule,
        EditorModule,
        EventsRoutingModule,
        FormsModule,
        MatCardModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatNativeDateModule,
        MatTableModule,
    ],
    exports: [
        EventAddComponent,
        EventEditComponent,
        EventListComponent,
        EventListPublicComponent
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
              ...MAT_DATE_FORMATS,
              dayHeaderFormat: 'mat-calendar-day-name-letter',
            }
          },
          provideNativeDateAdapter()
    ]
})
export class EventModule { }
