// src/app/pages/event/event-detail/event-detail.component.ts
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/_services/event.service';
import { Location } from '@angular/common';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { GoogleMapsService } from 'src/app/_services/google-maps.service';

@Component({
  selector: 'app-event-detail',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  eventId: string = '';
  event: any = null;

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private location: Location,
    private googleMapsService: GoogleMapsService
  ) {}

  ngOnInit(): void {
    // Get the event ID from the route
    this.eventId = this.route.snapshot.paramMap.get('id') || '';
    if (this.eventId) {
      this.loadEvent();
    }
  }

  loadEvent(): void {
    this.eventService.getEventDetail(this.eventId).subscribe({
      next: (data: any) => {
        this.event = data;
      },
      error: (err) => {
        console.error('Error fetching event detail:', err);
      }
    });
  }

  // Optional back navigation for convenience.
  goBack(): void {
    this.location.back();
  }

  // Helper to extract city name.
  getCityName(city: any): string {
    if (city && typeof city === 'object') {
      return city.name;
    }
    return '';
  }

    // Helper to extract state name.
    getStateCode(state: any): string {
        if (state && typeof state === 'object') {
            return state.code || state;
        }
        return '';
    }

    // Helper to extract state name.
    getStateName(state: any): string {
        if (state && typeof state === 'object') {
            return state.name || state;
        }
        return '';
    }
  

  /**
   * Uses the shared GoogleMapsService to open the event address in Google Maps.
   * @param event The current event containing the address.
   */
  openInGoogleMaps(event: any): void {
    if (event.address) {
      this.googleMapsService.openAddressInGoogleMaps(event.address);
    }
  }
}
