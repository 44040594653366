import { Component, OnInit } from '@angular/core';
import { MenuSubService } from 'src/app/_services/menu-sub.service';
import { EVENT_MENU_OPTIONS } from 'src/app/pages/event/event-menu/event-config';
import { EventService } from 'src/app/_services/event.service';
import { Event } from 'src/app/_interface/event';

@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {
    // The array that will hold the list of events from the server.
    events: Event[] = [];
    // Define the columns to display in the table.
    displayedColumns: string[] = ['name', 'date_start', 'date_end', 'is_online', 'actions'];

    constructor(
        private menuSubService: MenuSubService,
        private eventService: EventService
    ) {}

    ngOnInit(): void {
        // Update the submenu options (if your application uses dynamic menu options)
        this.menuSubService.updateSubMenuOptions(EVENT_MENU_OPTIONS);

        // Fetch the list of events from the API.
        this.eventService.listEvents().subscribe({
            next: (data: Event[]) => {
                this.events = data;
            },
            error: (error) => {
                console.error('Error fetching events:', error);
            }
        });
    }

    /**
     * Deletes an event and updates the table view.
     * @param eventId The unique identifier of the event to be deleted.
     */
    deleteEvent(eventId: string): void {
        if (!confirm('Are you sure you want to delete this event?')) {
            return;
        }

        this.eventService.deleteEvent(eventId).subscribe({
            next: () => {
                console.log('Event deleted successfully');
                // Remove the deleted event from the local list
                this.events = this.events.filter(event => event.id !== eventId);
            },
            error: (error) => {
                console.error('Error deleting event:', error);
            }
        });
    }
}
