// In src/app/app.component.ts
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { MessageService, Message } from 'src/app/_services/message.service';
import { AuthService } from 'src/app/_services/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'Patton Today';

    constructor(
        private snackBar: MatSnackBar,
        private messageService: MessageService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        console.log('Current Environment:', environment);

        // Process authentication callback if tokens are present in the URL hash
        this.authService.handleAuthCallback();

        // If an access token exists (from a previous session), rehydrate user details
        if (this.authService.getAccessToken()) {
            this.authService.loadCurrentUser();
        }

        // Subscribe to messages and display them using a MatSnackBar
        this.messageService.getMessage().subscribe((message: Message) => {
            this.snackBar.open(message.text, message.action, {
                duration: 3000, // Duration in milliseconds; adjust as needed
            });
        });
    }
}
