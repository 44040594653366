<!-- src/app/pages/event/event-detail/event-detail.component.html -->
<div class="event-detail-container" *ngIf="event; else loading">
  <!-- Event Header with Date Badge -->
  <div class="event-header">
    <div class="date-circle">
      <span class="month">{{ event.date_start | date:'MMM' }}</span>
      <span class="day">{{ event.date_start | date:'d' }}</span>
    </div>
    <h1 class="event-title">{{ event.name }}</h1>
    <h3 class="event-category" *ngIf="event.category">{{ event.category.name }}</h3>
  </div>

  <!-- Media Display -->
  <div class="event-media" *ngIf="event.media_primary && event.media_primary.file_url">
    <img [src]="event.media_primary.file_url" alt="Event Image" class="event-image" />
  </div>

  <!-- Event Information -->
  <div class="event-info">
    <!-- Full description -->
    <p class="event-description" [innerHTML]="event.description"></p>
    
    <!-- Event dates -->
    <div class="event-dates">
      <p><strong>Start:</strong> {{ event.date_start | date:'fullDate' }} {{ event.date_start | date:'shortTime' }}</p>
      <p><strong>End:</strong> {{ event.date_end | date:'fullDate' }} {{ event.date_end | date:'shortTime' }}</p>
    </div>
    
    <!-- Event location (if not online) -->
    <div class="event-location" *ngIf="!event.is_online && event.address">
      <p>
          <button mat-button (click)="openInGoogleMaps(event)">
              <mat-icon>location_on</mat-icon>
              {{ event.address.street }}, {{ getCityName(event.address.city) }}, 
              {{ getStateCode(event.address.state) }} {{ event.address.postal_code }}
          </button>
      </p>
    </div>
    
    <!-- Organization info -->
    <div class="event-organization" *ngIf="event.organization">
      <p><strong>Hosted by:</strong> {{ event.organization.name }}</p>
    </div>
    
    <!-- Additional notes -->
    <div class="event-notes" *ngIf="event.notes">
      <p><strong>Notes:</strong> {{ event.notes }}</p>
    </div>
  </div>
</div>

<ng-template #loading>
  <p>Loading event details...</p>
</ng-template>
