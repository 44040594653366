<div class="content-container">
  <h2>{{ ACTION }} {{ SUBJECT }}</h2>

  <form (ngSubmit)="onSubmit()">
    <!-- Catgory Information -->
    <fieldset>
      <legend>Event Category</legend>
      <div>
        <label for="category">Category:</label>
        <select
          id="category"
          name="category"
          [(ngModel)]="event.category!.id"
          required>
          <option value="" disabled>Select a Category</option>
          <option *ngFor="let cat of categories" [value]="cat.id">
            {{ cat.name }}
          </option>
        </select>
      </div>
    </fieldset>
    <!-- Basic Information -->
    <fieldset>
      <legend>Basic Information</legend>
      <div>
        <label for="name">Event Name:</label>
        <input
          id="name"
          type="text"
          name="name"
          [(ngModel)]="event.name"
          required
          aria-required="true"
        />
      </div>
      <div>
        <label for="description">Description:</label>
        <textarea
          id="description"
          name="description"
          [(ngModel)]="event.description"
        ></textarea>
      </div>
      <div style="visibility: collapse">
        <label for="notes">Notes:</label>
        <textarea
          id="notes"
          name="notes"
          [(ngModel)]="event.notes"
        ></textarea>
      </div>
    </fieldset>
    <!-- media file -->
    <fieldset>
      <legend>Media Upload</legend>
      <div>
        <label for="mediaFile">Upload Media:</label>
        <input type="file" id="mediaFile" (change)="onFileSelected($event)" />
      </div>
      <!-- Display the preview if the image is uploaded -->
      <div *ngIf="uploadedMediaUrl">
        <p>Uploaded Image Preview:</p>
        <img [src]="uploadedMediaUrl" alt="Uploaded Image Preview" style="max-width: 200px;"/>
      </div>
    </fieldset>
    <!-- Event Timing -->
    <fieldset>
      <legend>Event Timing</legend>
      <div>
        <label for="date_start">Start Date:</label>
        <input
          id="date_start"
          type="datetime-local"
          name="date_start"
          [(ngModel)]="event.date_start"
          required
          aria-required="true"
        />
      </div>
      <div>
        <label for="date_end">End Date:</label>
        <input
          id="date_end"
          type="datetime-local"
          name="date_end"
          [(ngModel)]="event.date_end"
          required
          aria-required="true"
        />
      </div>
    </fieldset>

    <!-- Event Location & Organization -->
    <fieldset>
      <legend>Event Location & Organization</legend>
      <div>
        <label for="website">Website:</label>
        <input
          id="website"
          type="url"
          name="website"
          [(ngModel)]="event.website"
          placeholder="https://example.com"
        />
      </div>
      <div>
        <label for="is_online">Is Online:</label>
        <input
          id="is_online"
          type="checkbox"
          name="is_online"
          [(ngModel)]="event.is_online"
          (change)="toggleLocationFields()"
        />
      </div>
      <!-- Organization Dropdown -->
      <div>
        <label for="organization">Organization:</label>
        <select
          id="organization"
          name="organization"
          [(ngModel)]="event.organization!.id"
          (change)="onOrgChange($event)"
          required
        >
          <option value="" disabled selected>Select an Organization</option>
          <option *ngFor="let org of organizations" [value]="org?.id">
            {{ org?.name }}
          </option>
        </select>
      </div>
      <!-- Address Dropdown (only if event is offline) -->
      <div *ngIf="!event.is_online">
        <label for="address">Address:</label>
        <select
          id="address"
          name="address"
          [(ngModel)]="event.address!.id"
          required
        >
          <option value="" disabled>Select an Address</option>
          <option *ngFor="let addr of addresses" [value]="addr?.id">
            {{ addr?.street }}, {{ getCityName(addr?.city) }}
          </option>
        </select>
      </div>
    </fieldset>

    <!-- Event Settings -->
    <fieldset>
      <legend>Event Settings</legend>
      <div>
        <label for="status">Status:</label>
        <select
          id="status"
          name="status"
          [(ngModel)]="event.status"
          aria-label="Event Status"
        >
          <option value="approved">Approved</option>
          <option value="draft">Draft</option>
          <option value="pending">Pending</option>
        </select>
      </div>
      <div>
        <input
          id="is_active"
          type="checkbox"
          name="is_active"
          [(ngModel)]="event.is_active"
        />
        <label for="is_active">Active</label>
      </div>
      <div>
        <input
          id="is_public"
          type="checkbox"
          name="is_public"
          [(ngModel)]="event.is_public"
        />
        <label for="is_public">Public</label>
      </div>
    </fieldset>

    <!-- Submit Button -->
    <button type="submit">Save</button>
  </form>
</div>
