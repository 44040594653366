import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface Message {
    text: string;
    action?: string;  // Optional action label (e.g., "Undo", "Retry", etc.)
}

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    private messageSubject = new Subject<Message>();

    // Call this method to send a message
    sendMessage(message: Message): void {
        this.messageSubject.next(message);
    }

    // Components can subscribe to this observable to receive messages
    getMessage(): Observable<Message> {
        return this.messageSubject.asObservable();
    }
}
