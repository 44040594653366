import { Component, OnInit } from '@angular/core';
import { Address } from 'src/app/_interface/address';
import { Category } from 'src/app/_interface/category';
import { City } from 'src/app/_interface/city';
import { EventDetail } from 'src/app/_interface/event_detail';
import { Organization } from 'src/app/_interface/organization';
import { CategoryService } from 'src/app/_services/category.service';
import { EventService } from 'src/app/_services/event.service';
import { MediaService } from 'src/app/_services/media.service';
import { OrganizationService } from 'src/app/_services/organization.service';
import { MessageService } from 'src/app/_services/message.service';

@Component({
    selector: 'app-event-add',
    templateUrl: './event-add.component.html',
    styleUrls: ['./event-add.component.css']
})
export class EventAddComponent implements OnInit {
    SUBJECT = 'event';
    ACTION = 'add';
    // Categories for the event.
    categories: Category[] = [];
    selectedFile: File | null = null;
    // New property to hold the uploaded media preview URL.
    uploadedMediaUrl: string = '';
    

    // Our event model, with default values.
    event: EventDetail = {
        id: '',
        address: null,
        category: null,
        date_created: '',
        date_modified: '',
        date_end: '',
        date_start: '',
        description: '',
        is_active: true,
        is_online: false,
        is_public: false,
        media_primary: null,
        name: '',
        notes: '',
        ordering: 0,
        organization: null,
        status: 'approved',
        website: ''
    };

    // Hold the list of organizations and addresses.
    organizations: Organization[] = [];
    addresses: Address[] = [];

    constructor(
        private categoryService: CategoryService,
        private eventService: EventService,
        private mediaService: MediaService,
        private organizationService: OrganizationService,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        // Load organizations and categories when the component initializes.
        this.loadOrganizations();
        this.loadCategories();
    }

    createEventWithMedia(): void {
        this.eventService.createEvent(this.event).subscribe({
            next: (response) => {
                console.log('Event created successfully:', response);
                this.resetForm();
                this.messageService.sendMessage({
                    text: 'Event successfully added!',
                    action: 'Success'
                });
            },
            error: (error) => {
                console.error('Error creating event:', error);
                this.messageService.sendMessage({
                    text: 'Failed to add event. Please try again.',
                    action: 'Error'
                });
            }
        });
    }

    // Fetch all categories from the API.
    loadCategories(): void {
        this.categoryService.listCategories('event').subscribe({
            next: (data: Category[]) => {
                this.categories = data;
                // Set the default selection to the category where value equals 'community'
                const defaultCat = this.categories.find(cat => cat.value === 'community');
                if (defaultCat) {
                    this.event.category = defaultCat;
                }
            },
            error: (err) => {
                console.error('Error fetching event categories:', err);
            }
        });
    }

    // Fetch all organizations from the API.
    loadOrganizations(): void {
        this.organizationService.OrganizationListPublic().subscribe({
            next: (data: Organization[]) => {
                this.organizations = data;
            },
            error: (error) => {
                console.error('Error loading organizations:', error);
            }
        });
    }

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
            this.selectedFile = input.files[0];
            // Immediately upload the file
            this.mediaService.upload(this.selectedFile).subscribe({
                next: (media) => {
                    // Assign to media_primary_id (not media_primary)
                    this.event.media_primary = media;
                    this.uploadedMediaUrl = media.file_url;
                    console.log('Media uploaded successfully:', media);
                },
                error: (error) => {
                    console.error('Error uploading media:', error);
                }
          });      
        }
    }

    // Called when the organization dropdown value changes.
    onOrganizationChange(orgId: string): void {
        this.event.organization!.id = orgId;
        this.organizationService.OrganizationAddressList(orgId).subscribe({
            next: (data: Address[]) => {
                console.log('DATA:' + JSON.stringify(data));
                this.addresses = data;
            },
            error: (error) => {
                console.error('Error loading addresses for organization:', error);
            }
        });
    }
    
    // Helper method to extract the organization value from the event.
    onOrgChange(e: Event): void {
        const selectElement = e.target as HTMLSelectElement;
        const orgId = selectElement.value;
        this.onOrganizationChange(orgId);
    }

    // If the event is marked as online, clear the address field and list.
    toggleLocationFields(): void {
        if (this.event.is_online) {
            this.event.address = null;
            this.addresses = [];
        }
    }

    // Resets the form to its initial state.
    resetForm(): void {
        this.event = {
            id: '',
            address: null,
            category: null,
            date_created: '',
            date_end: '',
            date_modified: '',
            date_start: '',
            description: '',
            is_active: true,
            is_online: false,
            is_public: false,
            media_primary: null,
            name: '',
            notes: '',
            ordering: 0,
            organization: null,
            status: 'approved',
            website: ''
        };
        this.addresses = [];
        this.selectedFile = null;
        this.uploadedMediaUrl = '';
    }

    // Submits the new event to the API.
    onSubmit(): void {
        // Since the media is already uploaded when selected,
        // we can directly create the event.
        this.createEventWithMedia();
    }

    getCityName(city: City | string | null | undefined): string {
        if (city && typeof city === 'object') {
            return city.name;
        }
        return '';
    }
}
