<!-- organization-edit.component.html -->
<div class="content-container">
    <button id="update-organization-cancel" mat-stroked-button color="warn" type="button" (click)="cancel()"><&nbsp;back to list</button>
    <h3>{{ organization?.name }}</h3>
    <p-tabView [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event)">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <mat-icon style="vertical-align: middle;">info</mat-icon> <span style="margin-left: 5px;">details</span>
            </ng-template>

            <!-- Main Organization Form -->
            <form *ngIf="organization" (ngSubmit)="onSubmit()">
                <fieldset>
                    <legend>Basic Information</legend>
                    <!-- Name -->
                    <div>
                        <label for="name">Name:</label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            [(ngModel)]="organization.name"
                            required
                            aria-required="true"
                        />
                    </div>

                    <!-- Organization Type -->
                    <div>
                        <label for="organization_type">Organization Type:</label>
                        <select
                            id="organization_type"
                            name="organization_type"
                            [(ngModel)]="organization.organization_type">
                            <option [ngValue]="null">Select organization type</option>
                            <option *ngFor="let option of organizationTypeChoices" [value]="option.value">
                                {{ option.label }}
                            </option>
                        </select>
                    </div>

                    <!-- Description -->
                    <div>
                        <label for="description">Description:</label>
                        <textarea
                            id="description"
                            name="description"
                            [(ngModel)]="organization.description">
                        </textarea>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>Contact Details</legend>
                    <!-- Email -->
                    <div>
                        <label for="email">Email:</label>
                        <input
                            id="email"
                            type="email"
                            name="email"
                            [(ngModel)]="organization.email"
                        />
                    </div>
                    <!-- Phone Number -->
                    <div>
                        <label for="phone_number">Phone Number:</label>
                        <input
                            id="phone_number"
                            type="text"
                            name="phone_number"
                            [(ngModel)]="organization.phone_number"
                        />
                    </div>
                    <!-- Website -->
                    <div>
                        <label for="website">Website:</label>
                        <input
                            id="website"
                            type="url"
                            name="website"
                            [(ngModel)]="organization.website"
                        />
                    </div>
                </fieldset>

                <fieldset>
                    <legend>Settings</legend>
                    <!-- Status -->
                    <div>
                        <label for="status">Status:</label>
                        <select id="status" name="status" [(ngModel)]="organization.status">
                            <option value="approved">Approved</option>
                            <option value="draft">Draft</option>
                            <option value="pending">Pending</option>
                        </select>
                    </div>
                    <!-- Active & Public Settings -->
                    <div>
                        <input
                            id="is_active"
                            type="checkbox"
                            name="is_active"
                            [(ngModel)]="organization.is_active"
                        />
                        <label for="is_active">Active</label>
                    </div>
                  <div>
                      <input
                          id="is_public"
                          type="checkbox"
                          name="is_public"
                          [(ngModel)]="organization.is_public"
                      />
                      <label for="is_public">Public</label>
                  </div>
                </fieldset>

                <!-- Submit Button for Organization -->
                <button id="update-organization" mat-stroked-button color="primary" type="submit">update</button>
                <button id="update-organization-cancel" mat-stroked-button color="warn" type="button" (click)="cancel()">Cancel</button>
            </form>
        </p-tabPanel>
        <!-- Addresses -->
        <p-tabPanel>
            <ng-template pTemplate="header">
                <mat-icon style="vertical-align: middle;">list</mat-icon> <span style="margin-left: 5px;">Addresses</span>
            </ng-template>
            <div class="panel-header">
                <button
                    id="add-organization-address"
                    aria-label="Add Organization Address"
                    class="button-action action-add"
                    mat-icon-button
                    title="Add Organization Address"
                    type="button"
                    (click)="activateAddAddressPanel()">
                        <mat-icon>add</mat-icon>
                </button>
            </div>
          
            <!-- Only display table if addresses exist -->
            <div *ngIf="organization && organization.addresses.length > 0">
                <table class="table">
                <thead>
                    <tr>
                    <th>
                        Identifier
                        <input 
                        type="text" 
                        (input)="onAddressFilterInput('identifier', $event)" 
                        placeholder="Filter Identifier" 
                        class="filter">
                    </th>
                    <th>
                        Street
                        <input 
                        type="text" 
                        (input)="onAddressFilterInput('street', $event)" 
                        placeholder="Filter Street" 
                        class="filter">
                    </th>
                    <th>
                        City
                        <input 
                        type="text" 
                        (input)="onAddressFilterInput('city', $event)" 
                        placeholder="Filter City" 
                        class="filter">
                    </th>
                    <th>
                        County
                        <input 
                        type="text" 
                        (input)="onAddressFilterInput('county', $event)" 
                        placeholder="Filter County" 
                        class="filter">
                    </th>
                    <th>
                        State
                        <input 
                        type="text" 
                        (input)="onAddressFilterInput('state', $event)" 
                        placeholder="Filter State" 
                        class="filter">
                    </th>
                    <th>
                        Postal Code
                        <input 
                        type="text" 
                        (input)="onAddressFilterInput('postal_code', $event)" 
                        placeholder="Filter Postal Code" 
                        class="filter">
                    </th>
                    <th>
                        Actions
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let address of pagedAddresses">
                    <td>{{ address.identifier }}</td>
                    <td>
                        {{ address.street }}<span *ngIf="address.unit">, {{ address.unit }}</span>
                    </td>
                    <td>{{ address.city?.name }}</td>
                    <td>{{ address.county?.name }}</td>
                    <td>{{ address.state?.name }}</td>

                    <td>{{ address.postal_code?.zip_code || address.postal_code }}</td>
                    <td>
                        <button 
                        mat-icon-button
                        id="delete-organization-address"
                        (click)="deleteAddress(address.id)" 
                        aria-label="Delete Address">
                        <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                    </tr>
                </tbody>
                </table>
            
                <!-- PrimeNG paginator for addresses -->
                <p-paginator 
                [first]="firstAddress" 
                [rows]="rowsAddress" 
                [totalRecords]="filteredAddresses.length" 
                (onPageChange)="onAddressPageChange($event)">
                </p-paginator>
            </div>
            <!-- Display message if there are no addresses -->
            <div *ngIf="organization && organization.addresses.length === 0">
                No addresses found.
            </div>
        </p-tabPanel>
        <!-- Add Address -->
        <p-tabPanel *ngIf="showAddAddressPanel">
            <ng-template pTemplate="header">
                <mat-icon style="vertical-align: middle;">add</mat-icon> <span style="margin-left: 5px;">Add Address</span>
            </ng-template>

            <div class="new-address-form">
                <button id="add-organization-address-cancel" color="warn" mat-stroked-button type="button" (click)="cancelAddAddress()"><- Cancel</button>

                <form (ngSubmit)="onAddressSubmit()">
                    <fieldset>
                        <legend>Basic Information</legend>
                        <div>
                            <label for="new_street">Street:</label>
                            <input
                                id="new_street"
                                type="text"
                                name="new_street"
                                [(ngModel)]="newAddress.street"
                                required
                                aria-required="true"
                            />
                        </div>
                        <div>
                            <label for="new_unit">Unit:</label>
                            <input
                                id="new_unit"
                                type="text"
                                name="new_unit"
                                [(ngModel)]="newAddress.unit"
                                required
                                aria-required="true"
                            />
                        </div>
                        <div>
                            <label for="new_postal_code">Postal Code:</label>
                            <input
                                id="new_postal_code"
                                type="text"
                                name="new_postal_code"
                                [(ngModel)]="newAddress.postal_code"
                                required
                                aria-required="true"
                                (blur)="lookupNewZipCode()"
                            />
                        </div>
                    </fieldset>
        
                    <fieldset>
                        <legend>Location</legend>
                        <div>
                            <label for="new_city">City:</label>
                            <input
                                id="new_city"
                                type="text"
                                name="new_city"
                                [(ngModel)]="newAddress.cityName"
                                readonly
                            />
                        </div>
                        <div>
                            <label for="new_county">County:</label>
                            <input
                                id="new_county"
                                type="text"
                                name="new_county"
                                [(ngModel)]="newAddress.countyName"
                                readonly
                            />
                        </div>
                        <div>
                            <label for="new_state">State:</label>
                            <input
                                id="new_state"
                                type="text"
                                name="new_state"
                                [(ngModel)]="newAddress.stateName"
                                readonly
                            />
                        </div>
                        <div>
                            <label for="new_country">Country:</label>
                            <input
                                id="new_country"
                                type="text"
                                name="new_country"
                                [(ngModel)]="newAddress.countryName"
                                readonly
                            />
                        </div>
                    </fieldset>
        
                    <fieldset>
                        <legend>Description</legend>
                        <div>
                            <label for="new_name">Name:</label>
                            <input
                                id="new_name"
                                type="text"
                                name="new_name"
                                [(ngModel)]="newAddress.name"
                                required
                                aria-required="true"
                            />
                        </div>
                        <div>
                            <label for="new_description">Description:</label>
                            <textarea
                                id="new_description"
                                name="new_description"
                                [(ngModel)]="newAddress.description"
                            ></textarea>
                        </div>
                    </fieldset>
        
                    <fieldset>
                        <legend>Address Settings</legend>
                        <div>
                            <label for="new_status">Status:</label>
                            <select id="new_status" name="new_status" [(ngModel)]="newAddress.status">
                                <option value="approved">Approved</option>
                                <option value="draft">Draft</option>
                                <option value="pending">Pending</option>
                            </select>
                        </div>
                        <div>
                            <input
                                id="new_is_active"
                                type="checkbox"
                                name="new_is_active"
                                [(ngModel)]="newAddress.is_active"
                            />
                            <label for="new_is_active">Active</label>
                        </div>
                        <div>
                            <input
                                id="new_is_public"
                                type="checkbox"
                                name="new_is_public"
                                [(ngModel)]="newAddress.is_public"
                            />
                            <label for="new_is_public">Public</label>
                        </div>
                    </fieldset>
                    <button id="add-organization-address" mat-stroked-button color="primary" type="submit">Add Address</button>
                    <button id="add-organization-address-cancel" mat-stroked-button color="warn" type="button" (click)="cancelAddAddress()">
                      Cancel
                    </button>
                </form>
            </div>
        </p-tabPanel>
        <!-- Events -->
        <p-tabPanel>
            <ng-template pTemplate="header">
                <mat-icon style="vertical-align: middle;">list</mat-icon><span style="margin-left: 5px;">Events</span>
            </ng-template>

            <div class="panel-header">
                <div class="panel-header">
                    <button
                        id="add-organization-event"
                        aria-label="Add Organization Event"
                        class="button-action action-add"
                        mat-icon-button
                        title="Add Organization Event"
                        type="button"
                        (click)="activateAddEventPanel()">
                            <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>
            <!-- Events Table -->
            <div *ngIf="organizationEvents!.length > 0; else noEvents">
              <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let orgEvent of organizationEvents">
                        <td>
                            <a (click)="loadEventForEdit(orgEvent.id)" style="cursor:pointer; text-decoration: underline;">
                                {{ orgEvent.name }}
                            </a>
                        </td>
                        <td>{{ orgEvent.date_start | date:'short' }}</td>
                        <td>{{ orgEvent.date_end | date:'short' }}</td>
                        <td>
                            <button mat-icon-button id="delete-organization-event" (click)="deleteEvent(orgEvent.id)" aria-label="Delete Event">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>                      
                </tbody>
              </table>
            </div>
            <ng-template #noEvents>
              <div>No events found.</div>
            </ng-template>
        </p-tabPanel>
        <!-- Add Event -->
        <p-tabPanel *ngIf="showAddEventPanel">
            <ng-template pTemplate="header">
                <mat-icon style="vertical-align: middle;">add</mat-icon> <span style="margin-left: 5px;">Add Event</span>
            </ng-template>
            <div class="new-event-form">
                <button id="add-organization-event-cancel" color="warn" mat-stroked-button type="button" (click)="cancelAddEvent()"><- Cancel</button>

                <form (ngSubmit)="onEventSubmit()">
                    <!-- Event Category -->
                    <fieldset>
                    <legend>Event Category</legend>
                    <div>
                        <label for="category">Category:</label>
                        <select
                        id="category"
                        name="category"
                        [(ngModel)]="event.category"
                        required>
                        <option [ngValue]="null" disabled>Select a Category</option>
                        <option *ngFor="let cat of categories" [ngValue]="cat">
                            {{ cat.name }}
                        </option>
                        </select>
                    </div>
                    </fieldset>
                    <!-- Basic Information -->
                    <fieldset>
                    <legend>Basic Information</legend>
                    <div>
                        <label for="name">Event Name:</label>
                        <input
                        id="name"
                        type="text"
                        name="name"
                        [(ngModel)]="event.name"
                        required
                        aria-required="true"
                        />
                    </div>
                    <div>
                        <label for="description">Description:</label>
                        <textarea
                        id="description"
                        name="description"
                        [(ngModel)]="event.description"
                        ></textarea>
                    </div>
                    </fieldset>
                    <!-- Media Upload -->
                    <fieldset>
                    <legend>Media Upload</legend>
                    <div>
                        <label for="mediaFile">Upload Media:</label>
                        <input type="file" id="mediaFile" (change)="onFileSelected($event)" />
                    </div>
                    <div *ngIf="uploadedMediaUrl">
                        <p>Uploaded Image Preview:</p>
                        <img [src]="uploadedMediaUrl" alt="Uploaded Image Preview" style="max-width: 200px;"/>
                    </div>
                    </fieldset>
                    <!-- Event Timing -->
                    <fieldset>
                    <legend>Event Timing</legend>
                    <div>
                        <label for="date_start">Start Date:</label>
                        <input
                        id="date_start"
                        type="datetime-local"
                        name="date_start"
                        [(ngModel)]="event.date_start"
                        required
                        aria-required="true"
                        />
                    </div>
                    <div>
                        <label for="date_end">End Date:</label>
                        <input
                        id="date_end"
                        type="datetime-local"
                        name="date_end"
                        [(ngModel)]="event.date_end"
                        required
                        aria-required="true"
                        />
                    </div>
                    </fieldset>
                    <!-- Event Location & Organization -->
                    <fieldset>
                    <legend>Event Location & Organization</legend>
                    <div>
                        <label for="website">Website:</label>
                        <input
                        id="website"
                        type="url"
                        name="website"
                        [(ngModel)]="event.website"
                        placeholder="https://example.com"
                        />
                    </div>
                    <div>
                        <label for="is_online">Is Online:</label>
                        <input
                        id="is_online"
                        type="checkbox"
                        name="is_online"
                        [(ngModel)]="event.is_online"
                        (change)="toggleLocationFields()"
                        />
                    </div>
                    <!-- Organization Dropdown (pre-selected) -->
                    <div>
                        <label for="organization">Organization:</label>
                        <select
                        id="organization"
                        name="organization"
                        [(ngModel)]="event.organization!.id"
                        disabled>
                        <option [value]="event.organization!.id">
                            {{ organization?.name }}
                        </option>
                        </select>
                    </div>
                    <!-- Address Dropdown (if offline) -->
                    <div *ngIf="!event.is_online">
                        <label for="address">Address:</label>
                        <select
                        id="address"
                        name="address"
                        [(ngModel)]="event.address"
                        required>
                        <option [ngValue]="null" disabled>Select an Address</option>
                        <option *ngFor="let addr of addressesForEvent" [ngValue]="addr">
                            {{ addr.street }}, {{ addr.city?.name }}
                        </option>
                        </select>
                    </div>
                    </fieldset>
                    <!-- Event Settings -->
                    <fieldset>
                    <legend>Event Settings</legend>
                    <div>
                        <label for="status">Status:</label>
                        <select
                        id="status"
                        name="status"
                        [(ngModel)]="event.status"
                        aria-label="Event Status">
                        <option value="approved">Approved</option>
                        <option value="draft">Draft</option>
                        <option value="pending">Pending</option>
                        </select>
                    </div>
                    <div>
                        <input
                        id="is_active"
                        type="checkbox"
                        name="is_active"
                        [(ngModel)]="event.is_active"
                        />
                        <label for="is_active">Active</label>
                    </div>
                    <div>
                        <input
                        id="is_public"
                        type="checkbox"
                        name="is_public"
                        [(ngModel)]="event.is_public"
                        />
                        <label for="is_public">Public</label>
                    </div>
                    </fieldset>
                    <!-- Submit Button -->
                    <button id="add-organization-event" mat-stroked-button type="submit" color="primary">Add Event</button>
                    <button id="add-organization-event-cancel" color="warn" mat-stroked-button type="button" (click)="cancelAddEvent()">Cancel</button>
                </form>
            </div>
        </p-tabPanel>
        <!-- Edit Event -->
        <p-tabPanel *ngIf="showEditEventPanel">
            <ng-template pTemplate="header">
                <mat-icon style="vertical-align: middle;">edit</mat-icon> <span style="margin-left: 5px;">Edit Event</span>
            </ng-template>
            <div class="new-event-form" *ngIf="editingEvent">
                <button id="edit-organization-event-cancel" color="warn" mat-stroked-button type="button" (click)="cancelEditEvent()"><- Cancel</button>

                <form (ngSubmit)="onEventSubmit()">
                    <!-- Event Category -->
                    <fieldset>
                        <legend>Event Category</legend>
                        <div>
                            <label for="category">Category:</label>
                            <select id="category" name="category"
                                [(ngModel)]="editingCategoryId"
                                required>
                                <option value="" disabled>Select a Category</option>
                                <option *ngFor="let cat of categories" [value]="cat.id">
                                    {{ cat.name }}
                                </option>
                            </select>

                        </div>
                    </fieldset>
                    <!-- Basic Information -->
                    <fieldset>
                    <legend>Basic Information</legend>
                    <div>
                        <label for="name">Event Name:</label>
                        <input
                        id="name"
                        type="text"
                        name="name"
                        [(ngModel)]="editingEvent.name"
                        required
                        aria-required="true"
                        />
                    </div>
                    <div>
                        <label for="description">Description:</label>
                        <textarea
                        id="description"
                        name="description"
                        [(ngModel)]="editingEvent.description"
                        ></textarea>
                    </div>
                    </fieldset>
                    <!-- Media Upload -->
                    <fieldset>
                    <legend>Media Upload</legend>
                    <div>
                        <label for="mediaFile">Upload Media:</label>
                        <input type="file" id="mediaFile" (change)="onFileSelected($event)" />
                    </div>
                    <div *ngIf="uploadedMediaUrl">
                        <p>Uploaded Image Preview:</p>
                        <img [src]="uploadedMediaUrl" alt="Uploaded Image Preview" style="max-width: 200px;"/>
                    </div>
                    </fieldset>
                    <!-- Event Timing -->
                    <fieldset>
                    <legend>Event Timing</legend>
                    <div>
                        <label for="date_start">Start Date:</label>
                        <input
                            id="date_start"
                            type="datetime-local"
                            name="date_start"
                            [(ngModel)]="editingEvent.date_start"
                            required
                            aria-required="true" />
                    </div>
                    <div>
                        <label for="date_end">End Date:</label>
                        <input
                            id="date_end"
                            type="datetime-local"
                            name="date_end"
                            [(ngModel)]="editingEvent.date_end"
                            required
                            aria-required="true" /> 
                        </div>
                    </fieldset>
                    <!-- Event Location & Organization -->
                    <fieldset>
                        <legend>Event Location & Organization</legend>
                        <div>
                            <label for="website">Website:</label>
                            <input
                                id="website"
                                type="url"
                                name="website"
                                [(ngModel)]="editingEvent.website"
                                placeholder="https://example.com"
                            />
                        </div>
                        <div>
                            <label for="is_online">Is Online:</label>
                            <input
                                id="is_online"
                                type="checkbox"
                                name="is_online"
                                [(ngModel)]="editingEvent.is_online"
                                (change)="toggleLocationFields()"
                            />
                        </div>
                    <!-- Organization Dropdown (pre-selected) -->
                    <div>
                        <label for="organization">Organization:</label>
                        <select
                            id="organization"
                            name="organization"
                            [(ngModel)]="editingEvent.organization!.id"
                            disabled>
                            <option [value]="editingEvent.organization!.id">
                                {{ organization?.name }}
                            </option>
                        </select>
                    </div>
                    <!-- Address Dropdown (if offline) -->
                    <div *ngIf="!editingEvent.is_online">
                        <label for="address">Address:</label>
                        <select id="address" name="address" [(ngModel)]="editingEvent.address!.id" required>
                          <option value="" disabled>Select an Address</option>
                          <option *ngFor="let addr of addressesForEvent" [value]="addr.id">
                            {{ addr.street }}, {{ addr.city?.name }}
                          </option>
                        </select>
                      </div>
                    </fieldset>
                    <!-- Event Settings -->
                    <fieldset>
                    <legend>Event Settings</legend>
                    <div>
                        <label for="status">Status:</label>
                        <select
                            id="status"
                            name="status"
                            [(ngModel)]="editingEvent.status"
                            aria-label="Event Status">
                            <option value="approved">Approved</option>
                            <option value="draft">Draft</option>
                            <option value="pending">Pending</option>
                        </select>
                    </div>
                    <div>
                        <input
                        id="is_active"
                        type="checkbox"
                        name="is_active"
                        [(ngModel)]="editingEvent.is_active"
                        />
                        <label for="is_active">Active</label>
                    </div>
                    <div>
                        <input
                        id="is_public"
                        type="checkbox"
                        name="is_public"
                        [(ngModel)]="editingEvent.is_public"
                        />
                        <label for="is_public">Public</label>
                    </div>
                    </fieldset>
                    <!-- Submit Button -->
                    <button id="edit-organization-event" mat-stroked-button type="submit" color="primary">Update Event</button>
                    <button id="edit-organization-event-cancel" color="warn" mat-stroked-button type="button" (click)="cancelEditEvent()">Cancel</button>
                </form>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
