
<!-- Scrollable table container -->
  <div class="content-container">
    <table mat-table [dataSource]="organizations" class="mat-elevation-z8">
  
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let organization"> {{ organization.name }} </td>
      </ng-container>
  
      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let organization"> {{ organization.description }} </td>
      </ng-container>
  
      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let organization"> {{ organization.email }} </td>
      </ng-container>
  
      <!-- Phone Number Column -->
      <ng-container matColumnDef="phone_number">
        <th mat-header-cell *matHeaderCellDef> Phone Number </th>
        <td mat-cell *matCellDef="let organization"> {{ organization.phone_number }} </td>
      </ng-container>
  
      <!-- Actions Column (Edit, Delete, and Website/Language) -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let organization">
          <!-- Website/Language Button -->
          <a *ngIf="organization.website"
             mat-icon-button
             [href]="organization.website"
             target="_blank"
             [matTooltip]="'go to ' + organization.website"
             aria-label="Go to website">
            <mat-icon>language</mat-icon>
          </a>
        </td>
      </ng-container>
  
      <!-- Header and Row Declarations -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  