<div class="content-container">
  <p>A directory of local addresses and residents like the old phone books and yellow pages.</p>
</div>

<!-- Add a scrollable wrapper -->
<div class="table-container">
  <table mat-table [dataSource]="addresses" class="mat-elevation-z8">
      <!-- Street Column -->
      <ng-container matColumnDef="street">
          <th mat-header-cell *matHeaderCellDef> Street </th>
          <td mat-cell *matCellDef="let address"> {{ address.street }} </td>
      </ng-container>

      <!-- City Column -->
      <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef> City </th>
          <td mat-cell *matCellDef="let address"> {{ address.city }} </td>
      </ng-container>

      <!-- State Column -->
      <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef> State </th>
          <td mat-cell *matCellDef="let address"> {{ address.state }} </td>
      </ng-container>

      <!-- Postal Code Column -->
      <ng-container matColumnDef="postal_code">
          <th mat-header-cell *matHeaderCellDef> Postal Code </th>
          <td mat-cell *matCellDef="let address"> {{ address.postal_code }} </td>
      </ng-container>

      <!-- Country Column -->
      <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef> Country </th>
          <td mat-cell *matCellDef="let address"> {{ address.country }} </td>
      </ng-container>

      <!-- Actions Column (Edit and Delete) -->
      <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let address">
              <!-- Edit Link -->
              <button mat-icon-button [routerLink]="['/address/edit', address.id]" aria-label="Edit Address">
                  <mat-icon>edit</mat-icon>
              </button>

              <!-- Trashcan Icon for Delete -->
              <button mat-icon-button color="warn" (click)="deleteAddress(address.id)" aria-label="Delete Address">
                  <mat-icon>delete</mat-icon>
              </button>
          </td>
      </ng-container>

      <!-- Header and Row Declarations -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
