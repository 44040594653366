import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PaginatorModule } from 'primeng/paginator';
import { TabViewModule } from 'primeng/tabview';


import { OrganizationAddComponent } from 'src/app/pages/organization/organization-add/organization-add.component';
import { OrganizationEditStaffComponent } from 'src/app/pages/organization/organization-edit-staff/organization-edit-staff.component';
import { OrganizationListStaffComponent } from 'src/app/pages/organization/organization-list-staff/organization-list-staff.component';
import { OrganizationListComponent } from 'src/app/pages/organization/organization-list/organization-list.component';
import { OrganizationMenuComponent } from 'src/app/pages/organization/organization-menu/organization-menu.component';
import { OrganizationRoutingModule } from 'src/app/pages/organization/organization-routing.module';

@NgModule({
    declarations: [
        OrganizationAddComponent,
        OrganizationEditStaffComponent,
        OrganizationListStaffComponent,
        OrganizationListComponent,
        OrganizationMenuComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        OrganizationRoutingModule,
        PaginatorModule,
        TabViewModule,
    ],
    exports: [
        OrganizationAddComponent,
        OrganizationEditStaffComponent,
        OrganizationListComponent,
        OrganizationMenuComponent,
    ]
})
export class OrganizationModule { }
