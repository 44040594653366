// app.module.ts
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule   } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list'
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';

import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { firstValueFrom } from 'rxjs';

import { OAuthModule } from 'angular-oauth2-oidc';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DialogModule } from 'primeng/dialog';

import { AuthService } from './_services/auth/auth.service';
import { AddressModule } from 'src/app/pages/address/address.module';
import { OrganizationModule } from './pages/organization/organization.module';
import { DealsModule } from './pages/deals/deals.module';
import { EventModule } from './pages/event/event.module';
import { HistoryModule } from './pages/history/history.module';
import { LayoutModule } from './layout/layout.module';
import { MarketModule } from './pages/market/market.module';
import { NewsModule } from './pages/news/news.module';
import { PagesModule } from './pages/pages.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthInterceptor } from './_services/auth/auth.interceptor';
import { RefreshInterceptor } from './_services/auth/refresh.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export function initializeApp(authService: AuthService): () => Promise<any> {
  return (): Promise<any> => {
    if (authService.getAccessToken()) {
      // Wait for the loadCurrentUser observable to complete.
      return firstValueFrom(authService.loadCurrentUser());
    }
    return Promise.resolve();
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],

  imports: [
    AddressModule,
    AppRoutingModule,
    BrowserModule,
    ClipboardModule,
    ConfirmDialogModule,    
    DialogModule,
    OrganizationModule,
    DealsModule,
    EventModule,
    HistoryModule,
    HttpClientModule,
    LayoutModule,
    MarketModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatTableModule,
    NewsModule,
    OAuthModule.forRoot(),
    PagesModule,
    RouterModule,
  ],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: RefreshInterceptor, multi: true },
      provideAnimationsAsync(),
      ConfirmationService,
      {
        provide: APP_INITIALIZER,
        useFactory: initializeApp,
        deps: [AuthService],
        multi: true,
      },
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
