import { Component, OnInit } from '@angular/core';
import { MenuSubService } from 'src/app/_services/menu-sub.service';
import { ADDRESS_MENU_OPTIONS } from 'src/app/pages/address/address-menu/address-config';
import { AddressService } from 'src/app/_services/address.service';
import { Address } from 'src/app/_interface/address';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.css']
})
export class AddressListComponent implements OnInit {
  addresses: Address[] = [];
  // Define the order and names of the columns that appear in the table.
  displayedColumns: string[] = ['street', 'city', 'state', 'postal_code', 'country', 'actions'];

  constructor(
    private menuSubService: MenuSubService,
    private addressService: AddressService
  ) {}

  ngOnInit(): void {
    // Update the sub menu options
    this.menuSubService.updateSubMenuOptions(ADDRESS_MENU_OPTIONS);

    // Fetch the list of addresses from the server
    this.addressService.AddressList().subscribe({
      next: (data: Address[]) => {
        this.addresses = data;
      },
      error: (error) => {
        console.error('Error fetching addresses:', error);
      }
    });
  }

  /**
   * Deletes an address and updates the table view.
   * @param addressId The unique identifier of the address to be deleted.
   */
  deleteAddress(addressId: string): void {
    // Optional: Confirm deletion with the user
    if (!confirm('Are you sure you want to delete this address?')) {
      return;
    }

    // Call the service to delete the address
    this.addressService.AddressDelete(addressId).subscribe({
      next: () => {
        console.log('Address deleted successfully');
        // Remove the deleted address from the table's data source
        this.addresses = this.addresses.filter(address => address.id !== addressId);
      },
      error: (error) => {
        console.error('Error deleting address:', error);
        // Optionally, display an error message using a snack bar or another messaging service
      }
    });
  }
}
