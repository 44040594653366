import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'src/app/_interface/address';
import { AddressAdd } from 'src/app/_interface/address_add';

import { City } from 'src/app/_interface/city';
import { ConfirmationService } from 'primeng/api';

import { Category } from 'src/app/_interface/category';
import { County } from 'src/app/_interface/county';
import { Event as AppEvent } from 'src/app/_interface/event';
import { EventAdd } from 'src/app/_interface/event_add';
import { EventDetail } from 'src/app/_interface/event_detail';
import { State } from 'src/app/_interface/state';
import { OrganizationDetail } from 'src/app/_interface/organization_detail';
import { ZipCode } from 'src/app/_interface/zipcode';

import { AddressService } from 'src/app/_services/address.service';
import { CategoryService } from 'src/app/_services/category.service';
import { EventService } from 'src/app/_services/event.service';
import { MediaService } from 'src/app/_services/media.service';
import { OrganizationService } from 'src/app/_services/organization.service';
import { ZipcodeService } from 'src/app/_services/zipcode.service';

@Component({
	selector: 'app-organization-edit-staff',
	templateUrl: './organization-edit-staff.component.html',
	styleUrls: ['./organization-edit-staff.component.css']
})
export class OrganizationEditStaffComponent implements OnInit {
	activeTabIndex: number = 0;
	addressFilters: { [key: string]: string } = {};
	addressesForEvent: Address[] = [];
	//displayedColumns: string[] = ['identifier', 'street', 'city', 'county', 'state', 'postal_code'];
	categories: Category[] = [];
	editingCategoryId: string | null = null;
	editingEvent: EventDetail | null = null;
	filteredAddresses: Address[] = [];
	firstAddress: number = 0;
	organizationId: string = '';
	organization?: OrganizationDetail;
	organizationEvents: AppEvent[] = [];
	pagedAddresses: Address[] = [];
	rowsAddress: number = 10;
	selectedFile: File | null = null;
	showAddAddressPanel: boolean = false;
	showAddEventPanel: boolean = false;	
	showEditEventPanel: boolean = false;	
	uploadedMediaUrl: string = '';

	// Event properties for the "Add Event" panel:
	event: EventDetail = {
		id: '',
		address: null,
		category: null,
		date_created: '',
		date_end: '',
		date_modified: '',
		date_start: '',
		description: '',
		is_active: true,
		is_online: false,
		is_public: false,
		media_primary: null,
		name: '',
		notes: '',
		ordering: 0,
		organization: null,
		status: 'approved',
		website: ''
	};


	// Initialize newAddress with all required properties.
	newAddress: AddressAdd & {
		// Additional display fields for location names.
		cityName?: string;
		countyName?: string;
		stateName?: string;
		countryName?: string;
	} = {
		street: '',
		postal_code: '',
		city_id: '',
		country_id: '0af22f77-9e81-4298-afec-f686b6c28a16',
		county_id: '',
		description: null,
		is_active: true,
		is_public: false,
		name: '',
		notes: null,
		state_id: '',
		status: 'approved',
		unit: '',
	};

	organizationTypeChoices = [
		{ value: 'government', label: 'Government & Public Services' },
		{ value: 'religious', label: 'Religious Organization' },
		{ value: 'nonprofit', label: 'Non‑Profit Organization' },
		{ value: 'business', label: 'Business Entity' },
		{ value: 'education', label: 'Educational Institution' },
		{ value: 'healthcare', label: 'Healthcare & Social Services' },
		{ value: 'agriculture', label: 'Agricultural & Farming Organization' },
		{ value: 'veterans', label: 'Veterans & Military Organization' },
		{ value: 'clubs', label: 'Clubs & Social Groups' },
		{ value: 'utilities', label: 'Utilities & Infrastructure' }
	];



	constructor(
		private addressService: AddressService,
		private categoryService: CategoryService,
		private eventService: EventService,
		private mediaService: MediaService,
		private organizationService: OrganizationService,
		private zipcodeService: ZipcodeService,
		private route: ActivatedRoute,
		private router: Router,
		private confirmationService: ConfirmationService
	) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
			this.organizationId = params.get('id') || '';
			if (this.organizationId) {
				this.organizationService.OrganizationDetail(this.organizationId).subscribe({
					next: (data: OrganizationDetail) => {
						this.organization = data;
						// Ensure addresses is initialized.
						if (!this.organization.addresses) {
							this.organization.addresses = [];
						}
						// Initialize filtered addresses and update the paged view.
						this.filteredAddresses = this.organization.addresses.slice();
						this.updatePagedAddresses();
						this.event.organization = this.organization ?? null;
					},
					error: (err) => console.error('Error fetching organization details:', err)
				});
			}
		});
	}

	activateAddAddressPanel(): void {
		this.showAddAddressPanel = true;
		// Wait for the view to update, then change the active tab.
		setTimeout(() => {
			// Assuming the "Add Address" tab is the third tab (index 2)
			this.activeTabIndex = 2;
		});
	}

	activateAddEventPanel(): void {
		this.showAddEventPanel = true;
		// Pre-select organization since it's already loaded
		this.event.organization = this.organization ?? null;
		if (this.organization?.website) {
			this.event.website = this.organization.website;
		}
		// Load categories and addresses for this organization
		this.loadEventCategories();
		this.loadOrganizationAddresses();
		setTimeout(() => {
			// If Add Address is not visible, Add Event will be at index 3; otherwise, it's at index 4.
			const index = this.showAddAddressPanel ? 4 : 3;
			this.activeTabIndex = index;
		});
	}

	/* Applies the filters to the organization's addresses. */
	applyAddressFilter(): void {
		let addresses = this.organization?.addresses || [];
		// Loop through each filter field and apply case-insensitive filtering.
		for (const key in this.addressFilters) {
			if (this.addressFilters[key]) {
				addresses = addresses.filter(address => {
					let fieldValue: string = '';
					if (key === 'postal_code') {
						fieldValue = (address.postal_code && typeof address.postal_code === 'object')
							? address.postal_code.zip_code
							: (address.postal_code ?? '');
					} else if (key === 'city') {
						fieldValue = (address.city && typeof address.city === 'object')
							? address.city?.name : '';
					} else if (key === 'county') {
						fieldValue = (address.county && typeof address.county === 'object')
							? address.county?.name : '';
					} else if (key === 'state') {
						fieldValue = (address.state && typeof address.state === 'object')
							? address.state?.name : '';
					} else {
						fieldValue = (address as any)[key] || '';
					}
					return fieldValue.toLowerCase().includes(this.addressFilters[key].toLowerCase());
				});
			}
		}
		this.filteredAddresses = addresses;
		this.firstAddress = 0; // Reset pagination to the first page.
		this.updatePagedAddresses();
	}
  
	cancel(): void {
		this.router.navigate(['/organization/list-staff']);
	}

	cancelAddAddress(): void {
		// Hide the "Add Address" panel
		this.showAddAddressPanel = false;
		// Reset the newAddress form if needed
		this.resetNewAddressForm();
		// Set the active tab to the "Addresses" panel (assuming its index is 1)
		this.activeTabIndex = 1;
	}

	cancelAddEvent(): void {
		// Hide the "Add Address" panel
		this.showAddEventPanel = false;
		// Reset the new Event form
		this.resetEventForm();
		// Set the active tab to the "Events" panel (assuming its index is2)
		this.activeTabIndex = 2;
	}

	cancelEditEvent(): void {
		this.showEditEventPanel = false;
		// Set the active tab to the "Events" panel (assuming its index is 2)
		this.activeTabIndex = 2;
	}
	
	/**
	 * Clears location fields for newAddress.
	 */
	clearNewAddressLocation(): void {
		this.newAddress.city_id = '';
		this.newAddress.cityName = '';
		this.newAddress.country_id = '';
		this.newAddress.countryName = '';
		this.newAddress.county_id = '';
		this.newAddress.countyName = '';
		this.newAddress.state_id = '';
		this.newAddress.stateName = '';
		this.newAddress.unit = '';
	}

	/**
	 * Deletes an address association after confirmation.
	 */
	deleteAddress(addressId: string): void {
		if (!this.organization) {
			return;
		}
		// Show confirmation dialog before deletion.
		this.confirmationService.confirm({
			message: `Are you sure you want to delete this address association? This action cannot be undone.`,
			header: 'Confirm Deletion',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.organizationService.unlinkAddress(this.organizationId, addressId).subscribe({
					next: () => {
						console.log('Address unlinked successfully');
						// Use non-null assertions to let TypeScript know addresses is defined.
						this.organization!.addresses = (this.organization!.addresses || []).filter(
							address => address.id !== addressId
						);
						// Reapply filters to update the pagedAddresses array.
						this.applyAddressFilter();
					},
					error: (error) => {
						console.error('Error unlinking address:', error);
					}
				});
			},
			reject: () => {
				// No action needed on rejection.
			}
		});
	}

	// Deletes an event by calling the EventService and updating the local events list.
	deleteEvent(eventId: string): void {
		if (!this.organization) {
			return;
		}
	
		this.confirmationService.confirm({
			message: 'Are you sure you want to delete this event? This action cannot be undone.',
			header: 'Confirm Deletion',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.eventService.deleteEvent(eventId).subscribe({
					next: () => {
						this.organizationEvents = this.organizationEvents.filter(e => e.id !== eventId);
						console.log('Event deleted successfully');
					},
					error: (err) => console.error('Error deleting event:', err)
				});
			},
			reject: () => {
				// Optional: log or toast cancel
				console.log('Event deletion canceled.');
			}
		});
	}

	formatForDatetimeLocal(dateStr: string): string {
		// Create a Date object from the incoming UTC date string.
		const date = new Date(dateStr);
		// Get the local date string in YYYY-MM-DD format.
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		return `${year}-${month}-${day}T${hours}:${minutes}`;
	}
		  
	/* Safely returns the city name. */
	getCityName(city: string | City): string {
		return typeof city === 'string' ? city : city?.name || '';
	}
	  
	/* Safely returns the county name. */
	getCountyName(county: string | County): string {
		return typeof county === 'string' ? county : county?.name || '';
	}

	/* Safely returns the state name. */
	getStateName(state: string | State): string {
		return typeof state === 'string' ? state : state?.name || '';
	}

	loadEventCategories(): void {
		this.categoryService.listCategories('event').subscribe({
			next: (data: Category[]) => {
				this.categories = data;
				// Optionally set a default category (e.g., where value equals 'community')
				const defaultCat = this.categories.find(cat => cat.value === 'community');
				if (defaultCat) {
					this.event.category = defaultCat;
				}
			},
			error: (err) => console.error('Error fetching event categories:', err)
		});
	}

	loadEventForEdit(eventId: string): void {
		this.categoryService.listCategories('event').subscribe({
			next: (cats) => {
				this.categories = cats;
				this.eventService.getEventDetail(eventId).subscribe({
				next: (data: EventDetail) => {
					// Create a flat edit model
					this.editingEvent = {
						id: data.id,
						category: data.category,
						address: data.address,
						name: data.name,
						date_created: data.date_created,
						date_modified: data.date_modified,
						media_primary: data.media_primary,
						description: data.description,
						// Convert the incoming date strings to a format acceptable by datetime-local
						date_start: data.date_start ? this.formatForDatetimeLocal(data.date_start) : '',
						date_end: data.date_end ? this.formatForDatetimeLocal(data.date_end) : '',
						is_active: data.is_active,
						is_public: data.is_public,
						is_online: data.is_online,
						ordering: data.ordering,
						website: data.website,
						status: data.status,
						organization: data.organization,
					};

					this.editingCategoryId = data.category?.id || null;
					this.editingEvent.status = data.status;
			
					if (data.media_primary && data.media_primary.file_url) {
						this.uploadedMediaUrl = data.media_primary.file_url;
					}
			
					// Load addresses if needed
					if (!data.is_online && data.organization) {
						this.organizationService.OrganizationAddressList(data.organization.id).subscribe({
						next: (addresses) => {
						this.addressesForEvent = addresses;
						},
						error: (err) => console.error('Error loading addresses:', err)
					});
					}
			
					// Hide the Add Event panel and show the Edit Event panel.
					this.showAddEventPanel = false;
					this.showEditEventPanel = true;
					setTimeout(() => {
					this.activeTabIndex = this.showAddAddressPanel ? 4 : 3;
					}, 0);
				},
					error: (err) => console.error('Error loading event details for editing:', err)
				});
			},
				error: (err) => console.error('Error loading categories:', err),
		});
	}
							  
	loadOrganizationAddresses(): void {
		this.organizationService.OrganizationAddressList(this.organizationId).subscribe({
			next: (data: Address[]) => {
				this.addressesForEvent = data;
				// Set default address if none is selected and the list is not empty
				if (data.length > 0 && !this.event.address) {
					this.event.address = data[0];
				}
			},
			error: (err) => console.error('Error fetching organization addresses:', err)
		});
	}
	
	// Loads events for the organization using its UUID.
	loadOrganizationEvents(): void {
		if (!this.organizationId) {
		  return;
		}
		this.eventService.listEvents({ organization: this.organizationId }).subscribe({
			next: (events: AppEvent[]) => {
				this.organizationEvents = events;
			},
			error: (err) => console.error('Error loading events:', err)
		});
	}	
	/**
	 * Looks up ZIP code details for the new address and populates location fields.
	 */
	lookupNewZipCode(): void {
		if (!this.newAddress.postal_code) {
			return;
		}

		this.zipcodeService.getZipCodeDetails(this.newAddress.postal_code).subscribe({
			next: (zipData: ZipCode) => {
				if (zipData && zipData.city && zipData.county && zipData.state) {
					// Save the IDs in the model for submission…
					this.newAddress.city_id = zipData.city.id;
					this.newAddress.county_id = zipData.county.id;
					this.newAddress.state_id = zipData.state.id;
					this.newAddress.country_id = zipData.country
						? zipData.country.id
						: '0af22f77-9e81-4298-afec-f686b6c28a16';
					// …and update display fields with names.
					this.newAddress.cityName = zipData.city.name;
					this.newAddress.countyName = zipData.county.name;
					this.newAddress.stateName = zipData.state.name;
					this.newAddress.countryName = 'United States of America';
				} else {
					this.clearNewAddressLocation();
				}
			},
			error: (err) => {
				console.error('Error fetching ZIP code details for new address:', err);
				this.clearNewAddressLocation();
			}
		});
	}

	/* Handles page change events from the paginator. */
	onAddressPageChange(event: any): void {
		this.firstAddress = event.first;
		this.rowsAddress = event.rows;
		this.updatePagedAddresses();
	}

	/* Called when a filter input is provided for a specific address field. */
	onAddressFilterInput(field: string, event: Event): void {
		const value = (event.target as HTMLInputElement).value;
		this.addressFilters[field] = value;
		this.applyAddressFilter();
	}

	/* Submits the new address form. */
	onAddressSubmit(): void {
		// Build a payload that includes only the expected properties
		const payload: AddressAdd = {
			city_id: this.newAddress.city_id,
			country_id: this.newAddress.country_id,
			county_id: this.newAddress.county_id,
			description: this.newAddress.description,
			is_active: this.newAddress.is_active,
			is_public: this.newAddress.is_public,
			name: this.newAddress.name,
			notes: this.newAddress.notes,
			postal_code: this.newAddress.postal_code,
			state_id: this.newAddress.state_id,
			status: this.newAddress.status,
			street: this.newAddress.street,
			unit: this.newAddress.unit,
		};
	
		this.addressService.AddressCreate(payload).subscribe({
			next: (createdAddress) => {
				console.log('Address created:', createdAddress);
				// Link the address to the organization.
				this.organizationService.linkAddress(this.organizationId, createdAddress.id).subscribe({
					next: (association) => {
						console.log('Address linked to organization:', association);
						if (this.organization) {
							// Check if the address already exists by comparing unique identifiers.
							const exists = this.organization.addresses?.some(
								(addr) => addr.id === createdAddress.id
							);
							if (!exists) {
								this.organization.addresses.push(createdAddress);
							} else {
								console.log('Address already exists in the organization.');
							}
						}
						// Similarly check for duplicates in the filteredAddresses array.
						if (!this.filteredAddresses.some(addr => addr.id === createdAddress.id)) {
							this.filteredAddresses.push(createdAddress);
						}
						this.updatePagedAddresses();
						// Reset the newAddress form.
						this.cancelAddAddress();
					},
					error: (err) => console.error('Error linking address:', err)
				});
			},
			error: (err) => console.error('Error creating address:', err)
		});
	}

	onEventSubmit(): void {
		if (this.showEditEventPanel && this.editingEvent) {
			// Before submitting, update the nested category with the flat value
			if (this.editingCategoryId) {
				const selectedCategory = this.categories.find(cat => cat.id === this.editingCategoryId);
				if (selectedCategory) {
				  this.editingEvent!.category = selectedCategory;
				}
			}
			this.eventService.updateEvent(this.editingEvent.id, this.editingEvent).subscribe({
				next: (updatedEvent: EventDetail) => {
					console.log('Event updated successfully:', updatedEvent);
					this.resetEventForm();
					this.loadOrganizationEvents();
					this.showEditEventPanel = false;
					this.activeTabIndex = 2;
				},
				error: (err) => console.error('Error updating event:', err)
			});
		} else {
			// Create new event as before
			this.eventService.createEvent(this.event).subscribe({
				next: (response: EventDetail) => {
					console.log('Event created successfully:', response);
					this.resetEventForm();
					this.loadOrganizationEvents();
					this.showAddEventPanel = false;
					this.activeTabIndex = 2;
				},
				error: (err) => console.error('Error creating event:', err)
			});
		}
	}
			
	onFileSelected(event: Event): void {
		const input = event.target as HTMLInputElement;
		if (input.files && input.files[0]) {
			this.selectedFile = input.files[0];
			this.mediaService.upload(this.selectedFile).subscribe({
				next: (media) => {
					this.event.media_primary = media;
					this.uploadedMediaUrl = media.file_url;
					console.log('Media uploaded successfully:', media);
				},
				error: (error) => {
					console.error('Error uploading media:', error);
				}
			});
		}
	}
	
	/**
	 * Updates the main organization details.
	 */
	onSubmit(): void {
		if (!this.organization) {
			return;
		}
		this.organizationService.OrganizationUpdate(this.organizationId, this.organization).subscribe({
			next: (updatedOrganization: OrganizationDetail) => {
				console.log('Organization updated successfully:', updatedOrganization);
				this.router.navigate(['/organization/list-staff']);
			},
			error: (err) => console.error('Error updating organization:', err)
		});
	}

	// Listen for tab changes to load events when the "Events" tab is opened.
	onTabChange(event: any): void {
		// Determine the events tab index.
		// If showAddAddressPanel is true, events tab might be index 3; otherwise, index 2.
		const eventsTabIndex = this.showAddAddressPanel ? 3 : 2;
		if (event.index === eventsTabIndex) {
			this.loadOrganizationEvents();
		}
	}

	resetEventForm(): void {
		this.event = {
			id: '',
			address: null,
			category: null,
			date_created: '',
			date_end: '',
			date_modified: '',
			date_start: '',
			description: '',
			is_active: true,
			is_online: false,
			is_public: false,
			media_primary: null,
			name: '',
			notes: '',
			ordering: 0,
			organization: this.organization ?? null,
			status: 'approved',
			website: ''
		};
		this.selectedFile = null;
		this.uploadedMediaUrl = '';
	}

	/**
	 * Resets the newAddress model.
	 */
	resetNewAddressForm(): void {
		this.newAddress = {
			city_id: '',
			country_id: '',
			county_id: '',
			description: null,
			is_active: true,
			is_public: false,
			name: '',
			notes: null,
			postal_code: '',
			state_id: '',
			status: 'approved',
			street: '',
			unit: '',
		};
	}

	toggleLocationFields(): void {
		if (this.event.is_online) {
			this.event.address = null;
			this.addressesForEvent = [];
		} else {
			// If toggled back, reload the addresses.
			this.loadOrganizationAddresses();
		}
	}

	updatePagedAddresses(): void {
		this.pagedAddresses = this.filteredAddresses.slice(this.firstAddress, this.firstAddress + this.rowsAddress);
	}
}
