/* today/src/app/pages/address/address-add/address-add.component.ts */
import { Component } from '@angular/core';
import { AddressAdd } from 'src/app/_interface/address_add';
import { AddressService } from 'src/app/_services/address.service';
import { ZipcodeService } from 'src/app/_services/zipcode.service';
import { ZipCode } from 'src/app/_interface/zipcode';
// Import the MessageService (adjust the path as needed)
import { MessageService } from 'src/app/_services/message.service';

@Component({
  selector: 'app-address-add',
  templateUrl: './address-add.component.html',
  styleUrls: ['./address-add.component.css']
})
export class AddressAddComponent {
  SUBJECT = 'address';
  ACTION = 'add';

  // Initial form model (adjust default values as desired)
  address: AddressAdd = {
    city_id: '',
    county_id: '',
    country_id: '0af22f77-9e81-4298-afec-f686b6c28a16',
    description: null,
    is_active: true,
    is_public: false,
    name: '',
    notes: null,
    state_id: '',
    status: 'approved',
    street: '',
    postal_code: ''
  };

  // These properties hold the display names for the dropdowns.
  cityName: string = '';
  countyName: string = '';
  stateName: string = '';
  countryName: string = '';

  constructor(
    private addressService: AddressService,
    private zipcodeService: ZipcodeService,
    private messageService: MessageService  // Inject MessageService here
  ) {}

  /**
   * Called when the postal code input loses focus.
   * Looks up ZIP code details and auto-populates the location fields.
   */
  lookupZipCode(): void {
    if (!this.address.postal_code) {
      return;
    }

    const zipCodeString = this.address.postal_code;

    this.zipcodeService.getZipCodeDetails(zipCodeString)
      .subscribe(
        (zipData: ZipCode) => {
          if (zipData && zipData.city && zipData.state && zipData.county) {
            // Update the model with the ID values...
            this.address.city_id = zipData.city.id;
            this.address.county_id = zipData.county.id;
            this.address.state_id = zipData.state.id;            
            // And update the display names...
            this.cityName = zipData.city.name;
            this.stateName = zipData.state.name;
            this.countyName = zipData.county.name;
            this.countryName = 'United States of America';
          } else {
            this.resetLocationFields();
          }
        },
        (error) => {
          console.error('Error fetching zip code details', error);
          this.resetLocationFields();
        }
      );
  }
  
  /**
   * Resets only the location-specific fields.
   */
  resetLocationFields(): void {
      this.address.city_id = '';
      this.cityName = '';
      this.address.county_id = '';
      this.countyName = '';
      this.address.country_id = '';
      this.countryName = '';
      this.address.state_id = '';
      this.stateName = '';
  }
  
  /**
   * Resets the entire form back to its default values.
   */
  resetForm(): void {
    this.address = {
      street: '',
      postal_code: '',
      city_id: '',
      county_id: '',
      state_id: '',
      country_id: '0af22f77-9e81-4298-afec-f686b6c28a16',
      name: '',
      description: null,
      notes: null,
      is_active: true,
      is_public: false,
      status: 'approved'
    };

    // Reset the display name properties as well
    this.cityName = '';
    this.countyName = '';
    this.stateName = '';
    this.countryName = '';
  }
  
  /**
   * Submits the form to create a new address.
   */
  onSubmit(): void {
    // Prepare the payload from the current form model
    const payload: AddressAdd = {
      street: this.address.street,
      postal_code: this.address.postal_code,
      city_id: this.address.city_id,
      county_id: this.address.county_id,
      state_id: this.address.state_id,
      country_id: this.address.country_id,
      name: this.address.name,
      description: this.address.description,
      notes: this.address.notes,
      status: this.address.status,
      is_active: this.address.is_active,
      is_public: this.address.is_public,
    };

    this.addressService.AddressCreate(payload).subscribe({
      next: (response) => {
        console.log('Address created successfully:', response);
        // Reset the form after a successful add
        this.resetForm();
        // Display a success message using the MessageService
        this.messageService.sendMessage({
          text: 'Address successfully added!',
          action: 'Success'
        });
        // Note: No navigation to the address list page here.
      },
      error: (error) => {
        console.error('Error creating address:', error);
        // Optionally display an error message
        this.messageService.sendMessage({
          text: 'Failed to add address. Please try again.',
          action: 'Error'
        });
      }
    });
  }
}
