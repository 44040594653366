import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ZipCode } from 'src/app/_interface/zipcode';

@Injectable({
    providedIn: 'root'
})
export class ZipcodeService {
    private baseUrl = `${environment.apiBaseUrl}zipcode/`;

    constructor(private http: HttpClient) {}

    /**
     * Fetch ZIP Code details from the API.
     * @param zipCode The ZIP Code to look up.
     * @returns Observable containing ZIP Code details.
     */
    getZipCodeDetails(zipCode: string): Observable<ZipCode> {
        return this.http.get<ZipCode>(`${this.baseUrl}${zipCode}/`);
    }
}
