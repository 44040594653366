/* src/app/_services/organization.service.ts */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Address } from 'src/app/_interface/address';
import { Organization } from 'src/app/_interface/organization';
import { OrganizationAdd } from 'src/app/_interface/organization_add';
import { OrganizationDetail } from 'src/app/_interface/organization_detail';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  // Base URL for the organization endpoints.
  private baseUrl = `${environment.apiBaseUrl}organization/`;

  constructor(private http: HttpClient) {}

  OrganizationAddressList(orgId: string): Observable<Address[]> {
      const url = `${environment.apiBaseUrl}organization/${orgId}/addresses/`;
      return this.http.get<Address[]>(url);
  }
  
  /**
   * Create a new organization.
   * @param organization - The organization data to create.
   * @returns Observable containing the created organization's details.
   */
  OrganizationCreate(organization: OrganizationAdd): Observable<OrganizationDetail> {
    return this.http.post<OrganizationDetail>(`${this.baseUrl}create/`, organization);
  }

  /**
   * Delete an organization by UUID.
   * @param organizationId - The UUID of the organization to delete.
   * @returns Observable of type void.
   */
  OrganizationDelete(organizationId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}${organizationId}/delete/`);
  }

  /**
   * Retrieve details of an organization by UUID.
   * @param organizationId - The UUID of the organization.
   * @returns Observable containing the organization's details.
   */
  OrganizationDetail(organizationId: string): Observable<OrganizationDetail> {
    return this.http.get<OrganizationDetail>(`${this.baseUrl}${organizationId}/`);
  }

  /**
   * Update an existing organization.
   * @param organizationId - The UUID of the organization to update.
   * @param organization - The updated organization data.
   * @returns Observable containing the updated organization's details.
   */
  OrganizationUpdate(organizationId: string, organization: OrganizationDetail): Observable<OrganizationDetail> {
    return this.http.put<OrganizationDetail>(`${this.baseUrl}${organizationId}/edit/`, organization);
  }

  /**
   * Retrieve a list of organizations.
   * @returns Observable containing an array of organizations.
   */
  OrganizationListPublic(): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.baseUrl);
  }

  OrganizationListStaff(): Observable<Organization[]> {
      const url = `${this.baseUrl}staff/`;
      return this.http.get<Organization[]>(url);
  }


    /**
         * Link an address to an organization.
         * @param organizationId - The organization’s UUID.
         * @param addressId - The newly created address’s UUID.
         * @returns Observable of the created association.
     */
    linkAddress(organizationId: string, addressId: string): Observable<any> {
        const url = `${this.baseUrl}${organizationId}/link-address/`;
        // The payload keys must match what the serializer expects.
        return this.http.post<any>(url, { organization_id: organizationId, address_id: addressId });
    }

    /**
         * Unlink an address from an organization.
         * @param organizationId - The organization’s UUID.
         * @param addressId - The address’s UUID to be unlinked.
         * @returns Observable of type void.
    */
    unlinkAddress(organizationId: string, addressId: string): Observable<void> {
        const url = `${this.baseUrl}${organizationId}/unlink-address/${addressId}/`;
        return this.http.delete<void>(url);
    }
}
