<div *ngIf="false">today/src/app/pages/address/address-edit/address-edit.component.html </div>  
<div class="content-container">
    <h2>Edit Address</h2>

    <form *ngIf="address" (ngSubmit)="onSubmit()">
        <fieldset>
            <legend>Basic Information</legend>
            <!-- Street -->
            <div>
                <label for="street">Street:</label>
                <input id="street" type="text" name="street" [(ngModel)]="address.street" required aria-required="true" />
            </div>
            <!-- Postal Code -->
            <div>
                <label for="postal_code">Postal Code:</label>
                <input id="postal_code" type="text" name="postal_code" [(ngModel)]="zipCode" aria-label="Postal Code" />
            </div>
        </fieldset>

        <fieldset>
            <legend>Location</legend>

            <div>
                <label for="city">City:</label>
                <select id="city" name="city" [(ngModel)]="address.city" disabled>
                    <option *ngIf="address.city" [value]="address.city">{{ cityName }}</option>
                </select>
            </div>
              
            <div>
                <label for="county">County:</label>
                <select id="county" name="county" [(ngModel)]="address.county" disabled>
                    <option *ngIf="address.county" [value]="address.county">{{ countyName }}</option>
                </select>
            </div>

            <div>
                <label for="state">State:</label>
                <select id="state" name="state" [(ngModel)]="address.state" disabled>
                    <option *ngIf="address.state" [value]="address.state">{{ stateName }}</option>
                </select>
            </div>


            <div>
                <label for="country">Country:</label>
                <select id="country" name="country" [(ngModel)]="address.country" disabled>
                    <option *ngIf="address.country" [value]="address.country">{{ countryName }}</option>
                </select>
            </div>
        </fieldset>

        <fieldset>
            <legend>Description</legend>
            <!-- Name -->
            <div>
                <label for="name">Name:</label>
                <input id="name" type="text" name="name" [(ngModel)]="address.name" required aria-required="true" />
            </div>
            <div>
                <label for="description">Description:</label>
                <textarea id="description" name="description" [(ngModel)]="address.description"></textarea>
            </div>
        </fieldset>

        <fieldset>
            <legend>Address Settings</legend>
            <!-- Status -->
            <div>
                <label for="status">Status:</label>
                <select id="status" name="status" [(ngModel)]="address.status" aria-label="Address Status">
                    <option value="approved">Approved</option>
                    <option value="draft">Draft</option>
                    <option value="pending">Pending</option>
                </select>
            </div>
            <!-- Active & Public Settings -->
            <div>
                <input id="is_active" type="checkbox" name="is_active" [(ngModel)]="address.is_active" />
                <label for="is_active">Active</label>
            </div>
            <div>
                <input id="is_public" type="checkbox" name="is_public" [(ngModel)]="address.is_public" />
                <label for="is_public">Public</label>
            </div>
        </fieldset>

        <!-- Submit Button -->
        <button type="submit">Save</button>
    </form>
</div>
