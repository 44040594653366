import { Component } from '@angular/core';

@Component({
    selector: 'app-news-list-admin',
    standalone: false,
    templateUrl: './news-list-admin.component.html',
    styleUrl: './news-list-admin.component.scss'
})
export class NewsListAdminComponent {

}
