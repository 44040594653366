import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationService } from 'src/app/_services/organization.service';
import { OrganizationAdd } from 'src/app/_interface/organization_add';

@Component({
  selector: 'app-organization-add',
  templateUrl: './organization-add.component.html',
  styleUrls: ['./organization-add.component.css']
})
export class OrganizationAddComponent {
  // Initialize a new organization object with default values
  newOrganization: OrganizationAdd = {
    name: '',
    description: '',
    organization_type: null,
    email: null,
    phone_number: null,
    website: null,
    notes: '',
    ordering: 0,
    is_active: true,
    is_public: true
  };

  // Optional: Provide a list of organization type choices (similar to your edit component)
  organizationTypeChoices = [
    { value: 'government', label: 'Government & Public Services' },
    { value: 'religious', label: 'Religious Organization' },
    { value: 'nonprofit', label: 'Non-Profit Organization' },
    { value: 'business', label: 'Business Entity' },
    { value: 'education', label: 'Educational Institution' },
    { value: 'healthcare', label: 'Healthcare & Social Services' },
    { value: 'agriculture', label: 'Agricultural & Farming Organization' },
    { value: 'veterans', label: 'Veterans & Military Organization' },
    { value: 'clubs', label: 'Clubs & Social Groups' },
    { value: 'utilities', label: 'Utilities & Infrastructure' }
  ];

  constructor(
    private organizationService: OrganizationService,
    private router: Router
  ) {}

  onSubmit(): void {
    // Convert empty strings to null
    if (this.newOrganization.website === '') {
      this.newOrganization.website = null;
    }
    if (this.newOrganization.email === '') {
      this.newOrganization.email = null;
    }
    // Now call the service:
    this.organizationService.OrganizationCreate(this.newOrganization).subscribe({
      next: (createdOrg) => {
        console.log('Organization created successfully:', createdOrg);
        this.router.navigate(['/organization/list']);
      },
      error: (error) => {
        console.error('Error creating organization:', error);
      }
    });
  }
}
