<div class="content-container">
    <h2>Add Organization</h2>
    <form #orgForm="ngForm" (ngSubmit)="onSubmit()">
      <fieldset>
        <legend>Basic Information</legend>
        <!-- Name -->
        <div>
          <label for="name">Name:</label>
          <input
            id="name"
            type="text"
            name="name"
            [(ngModel)]="newOrganization.name"
            required
            aria-required="true"
          />
        </div>
    
        <!-- Organization Type -->
        <div>
          <label for="organization_type">Organization Type:</label>
          <select
            id="organization_type"
            name="organization_type"
            [(ngModel)]="newOrganization.organization_type">
            <option [ngValue]="null">Select organization type</option>
            <option *ngFor="let option of organizationTypeChoices" [value]="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
    
        <!-- Description -->
        <div>
          <label for="description">Description:</label>
          <textarea
            id="description"
            name="description"
            [(ngModel)]="newOrganization.description">
          </textarea>
        </div>
      </fieldset>
    
      <fieldset>
        <legend>Contact Details</legend>
        <!-- Email -->
        <div>
          <label for="email">Email:</label>
          <input
            id="email"
            type="email"
            name="email"
            [(ngModel)]="newOrganization.email"
          />
        </div>
        <!-- Phone Number -->
        <div>
          <label for="phone_number">Phone Number:</label>
          <input
            id="phone_number"
            type="text"
            name="phone_number"
            [(ngModel)]="newOrganization.phone_number"
          />
        </div>
        <!-- Website -->
        <div>
          <label for="website">Website:</label>
          <input
            id="website"
            type="url"
            name="website"
            [(ngModel)]="newOrganization.website"
          />
        </div>
      </fieldset>
    
      <fieldset>
        <legend>Settings</legend>
        <!-- Ordering -->
        <div>
          <label for="ordering">Ordering:</label>
          <input
            id="ordering"
            type="number"
            name="ordering"
            [(ngModel)]="newOrganization.ordering"
          />
        </div>
        <!-- Active -->
        <div>
          <input
            id="is_active"
            type="checkbox"
            name="is_active"
            [(ngModel)]="newOrganization.is_active"
          />
          <label for="is_active">Active</label>
        </div>
        <!-- Public -->
        <div>
          <input
            id="is_public"
            type="checkbox"
            name="is_public"
            [(ngModel)]="newOrganization.is_public"
          />
          <label for="is_public">Public</label>
        </div>
      </fieldset>
    
      <button type="submit" [disabled]="orgForm.invalid">Create Organization</button>
    </form>
  </div>
  