// src/app/_services/auth/auth.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly siteId = environment.siteId;
  // Define your API base URL to help decide which requests are internal.
  private readonly apiBaseUrl = environment.apiBaseUrl || 'https://patton.today/api/';

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem('access_token');

    // Clone the headers from the request.
    let customHeaders: { [key: string]: string } = {};

    // Only attach our custom headers for requests targeting our API.
    if (req.url.startsWith(this.apiBaseUrl)) {
      customHeaders['X-Site-ID'] = this.siteId;
    }

    // For state-changing requests, attach the CSRF token.
    const method = req.method.toUpperCase();
    if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
      const csrfToken = this.getCookie('csrftoken');
      console.log('CSRF Token extracted:', csrfToken);
      if (csrfToken) {
        customHeaders['X-CSRFToken'] = csrfToken;
      } else {
        console.warn('No CSRF token found in cookies.');
      }
    }

    // Attach the authorization header if a token exists.
    if (token) {
      customHeaders['Authorization'] = `Bearer ${token}`;
    }

    // Clone the request with the custom headers merged.
    const cloned = req.clone({ setHeaders: customHeaders });
    return next.handle(cloned);
  }

  // Helper function to retrieve a cookie value by name.
    private getCookie(name: string): string | null {
        const nameEQ = name + "=";
        const cookies = document.cookie.split(';');
        for (let cookie of cookies) {
            cookie = cookie.trim();
            if (cookie.indexOf(nameEQ) === 0) {
                return decodeURIComponent(cookie.substring(nameEQ.length));
            }
        }
        return null;
    }
}
