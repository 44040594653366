// src/app/_services/auth/refresh.interceptor.ts
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    constructor(
        private authService: AuthService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Do not intercept refresh token requests
        if (request.url.includes('/api/token/refresh/')) {
        return next.handle(request);
        }

        return next.handle(request).pipe(
        catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
            // If a refresh is not in progress, start one
            if (!this.isRefreshing) {
                this.isRefreshing = true;
                this.refreshTokenSubject.next(null);

                return this.authService.refreshToken().pipe(
                switchMap((response: any) => {
                    // Assume the new access token is in response.access
                    const newToken = response.access;
                    // Update sessionStorage with the new token
                    sessionStorage.setItem('access_token', newToken);
                    this.refreshTokenSubject.next(newToken);

                    // Retry the failed request with the new token
                    return next.handle(this.addToken(request, newToken));
                }),
                catchError(err => {
                    // If refresh fails, logout the user
                    this.authService.logout();
                    return throwError(() => err);
                }),
                // Reset refreshing state whether the call succeeded or failed
                // finalize(() => this.isRefreshing = false)
                );
            } else {
                // If refresh is in progress, wait for it to complete
                return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => next.handle(this.addToken(request, token!)))
                );
            }
            }
            return throwError(() => error);
        })
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
