<table id="neader-table">
    <tr id="header-background">
        <td id="header-logo">
            <a [routerLink]="['/home']">
                <img src="assets/images/logo-patton-today.png" id="today-logo" alt="Banner">
            </a>
        </td>
        <td id="header-avatar" >
            <table class="desktop">
                <tr>
                    <div class="desktop-nav">
                        <app-nav></app-nav>
                    </div>
                    <!-- 
                    <p-avatar *ngIf="userPicture" image="{{ userPicture }}" class="mr-2" shape="circle" />
                    -->
                  </tr>
            </table>
            <table class="compact">
                <tr>
                    <td class="avatar">
                        <img src="assets/images/menu-burger-patton-today.png" (click)="toggleMenu()">
                    </td>
                </tr>
            </table>
        </td>
</table>
<div class="mobile-nav">
    <app-nav></app-nav>
  </div>

