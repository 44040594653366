import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddressAddComponent } from 'src/app/pages/address/address-add/address-add.component';
import { AddressEditComponent } from 'src/app/pages/address/address-edit/address-edit.component';
import { AddressListComponent } from 'src/app/pages/address/address-list/address-list.component';

const routes: Routes = [
    { path: 'add', component: AddressAddComponent },
    { path: 'edit/:id', component: AddressEditComponent },
    { path: 'list', component:  AddressListComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AddressRoutingModule { }
