// src/app/_services/auth/auth.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'; 

import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { getAuthConfig, googleAuthConfig } from './auth.config';
import { environment } from 'src/environments/environment.prod';
import { AuthTokenResponse } from 'src/app/_interface/auth_token';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
    private userDetailsSubject = new BehaviorSubject<any>(null);
    private userFirstName = new BehaviorSubject<string>(sessionStorage.getItem('firstname') || '');
    private userLastName = new BehaviorSubject<string>(sessionStorage.getItem('lastname') || '');
    private userFullName = new BehaviorSubject<string>(sessionStorage.getItem('fullname') || '');
    private userPicture = new BehaviorSubject<string>(sessionStorage.getItem('picture') || '');
    private userGroups = new BehaviorSubject<string[]>([]);
    public userIsStaff = new BehaviorSubject<boolean>(false);

    private accessTokenKey = 'access_token';
    private refreshTokenKey = 'refresh_token';

    constructor(
        private oauthService: OAuthService,
        private http: HttpClient,
        private router: Router
    ) {
        if (environment.useFakeAuth) {
            this.fakeLogin();
        } else {
            this.checkIfUserIsLoggedIn();
        }
    }

    private checkIfUserIsLoggedIn() {
        const accessToken = sessionStorage.getItem(this.accessTokenKey);
        if (accessToken) {
            console.log('User is already logged in.');
            this.isAuthenticatedSubject.next(true);
        } else {
            console.log('User is not logged in.');
        }
    }

    loginWithGoogle() {
        // Redirect to Django's Allauth Google login endpoint
        window.location.href = 'https://patton.today/api/accounts/google/login/';
    }

    loginWithFacebook() {
        const config = getAuthConfig('facebook');
        const clientId = config.clientId || 'defaultClientId';
        const redirectUri = config.redirectUri || 'defaultRedirectUri';

        const url = `https://www.facebook.com/dialog/oauth?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=email,public_profile&response_type=token`;
        window.location.href = url;
    }

    private configureOAuth(provider: 'google' | 'facebook') {
        const config = getAuthConfig(provider);
        this.oauthService.configure(config);

        if (provider === 'google') {
            this.oauthService.loadDiscoveryDocument();
        }
    }

    handleAuthCallback(): void {
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);
        const idToken = params.get('id_token');
        const accessToken = params.get('access_token');
    
        if (idToken && accessToken) {
            // Process the tokens by sending the idToken to your backend
            this.sendTokenToBackend(idToken).subscribe({
                next: (response) => {
                    console.log('Backend response:', response);
            
                    // Store tokens (only tokens are stored for persistence)
                    sessionStorage.setItem(this.accessTokenKey, response.access);
                    sessionStorage.setItem(this.refreshTokenKey, response.refresh);
            
                    // Extract user details and update in-memory state
                    const user = response.user;
                    // If you want to remove sensitive properties, do so here:
                    // delete user.is_staff;
                    this.setUserIsStaff(user.is_staff);
                    this.setUserDetails(user);
            
                    // Update authentication state
                    this.isAuthenticatedSubject.next(true);
            
                    // Clean up the URL by removing the hash fragment
                    window.history.replaceState({}, document.title, window.location.pathname);
                },
                error: (err) => {
                    console.error('Error verifying token with backend:', err);
                },
            });
        }
    }
  
    handleSuccessfulLogin() {
        const token = sessionStorage.getItem('access_token');
        const jdata = sessionStorage.getItem('id_token_claims_obj');
        if (jdata && token) {
            const uData = JSON.parse(jdata);
            const { email, given_name, family_name, picture, locale } = uData;

            this.sendToken(token, email, given_name, family_name, picture, locale).subscribe({
                next: (response: AuthTokenResponse) => {
                    console.log('Token sent successfully', response);
                    if (response.access && response.refresh) {
                        this.storeTokens(response.access, response.refresh);
                        this.isAuthenticatedSubject.next(true);
                    } else {
                        console.error('Missing access or refresh token in the response');
                    }
                },
                error: (error) => console.error('Error sending token', error)
            });
        } else {
            console.log('No user data in id_token_claims_obj');
        }
    }

    private storeTokens(access: string, refresh: string) {
        console.log('Storing tokens:', access, refresh);
        sessionStorage.setItem(this.accessTokenKey, access);
        sessionStorage.setItem(this.refreshTokenKey, refresh);

        // Check if the values were successfully stored
        const storedAccessToken = sessionStorage.getItem(this.accessTokenKey);
        const storedRefreshToken = sessionStorage.getItem(this.refreshTokenKey);
        if (storedAccessToken && storedRefreshToken) {
            console.log('Tokens stored successfully');
        } else {
            console.error('Failed to store tokens');
        }
    }

    getAccessToken(): string | null {
        return sessionStorage.getItem(this.accessTokenKey);
    }

    getJWTForLoggedInUser(): Observable<any> {
        const url = 'https://patton.today/api/auth/get_jwt/';
        return this.http.get(url, { withCredentials: true });
    }

    getRefreshToken(): string | null {
        return sessionStorage.getItem(this.refreshTokenKey);
    }

    // Expose user details as an observable
    getUserDetails(): Observable<any> {
        return this.userDetailsSubject.asObservable();
    }

    getUserGroups(): Observable<string[]> {
        return this.userGroups.asObservable();
    }

    getUserIsStaff(): Observable<boolean> {
        return this.userIsStaff.asObservable();
    }

    isUserInGroup(groupName: string): boolean {
        const groups = this.userGroups.getValue();
        return groups.includes(groupName);
    }

    logout() {
        this.oauthService.logOut();
        sessionStorage.clear();
        this.isAuthenticatedSubject.next(false);
        this.userDetailsSubject.next(null);
        sessionStorage.removeItem('currentUser');
        this.setUserIsStaff(false);
        this.resetUserDetails();
        
        // Redirect to /home
        this.router.navigate(['/home']);
    }

    sendTokenToBackend(idToken: string): Observable<any> {
        const url = 'https://patton.today/api/auth/verify-google-token/';
        return this.http.post(url, { id_token: idToken });
    }

    refreshToken(): Observable<any> {
        const refreshToken = this.getRefreshToken();
        if (refreshToken) {
            return this.http.post(`${environment.apiBaseUrl}/api/token/refresh/`, {
                refresh: refreshToken
            });
        } else {
            throw new Error('No refresh token available');
        }
    }

    private resetUserDetails() {
        this.setUserFirstName('');
        this.setUserFullName('');
        this.setUserLastName('');
        this.setUserPicture('');
    }

    sendToken(token: string, email: string, name_first: string, name_last: string, picture: string, locale: string): Observable<AuthTokenResponse> {
        const url = `${environment.apiBaseUrl}/api/auth/google/login/`;
        return this.http.post<AuthTokenResponse>(url, { token, email, name_first, name_last, picture, locale });
    }

    isAuthenticated(): Observable<boolean> {
        return this.isAuthenticatedSubject.asObservable();
    }

    userDetails(): Observable<any> {
        return this.userDetailsSubject.asObservable();
    }

    private storeUserDetails() {
        const jdata = sessionStorage.getItem('id_token_claims_obj');
        if (jdata) {
            const uData = JSON.parse(jdata);
            this.userDetailsSubject.next(uData);
        } else {
            console.log('No user data in id_token_claims_obj');
        }
    }

    // Getter functions for user information

    getUserFirstName(): Observable<string> {
        return this.userFirstName.asObservable();
    }

    getUserLastName(): Observable<string> {
        return this.userLastName.asObservable();
    }

    getUserFullName(): Observable<string> {
        return this.userFullName.asObservable();
    }

    getUserPicture(): Observable<string> {
        return this.userPicture.asObservable();
    }

    loadCurrentUser(): Observable<any> {
        const accessToken = this.getAccessToken();
        if (accessToken) {
            return this.http.get<any>('https://patton.today/api/user/current/').pipe(
                tap(userData => {
                this.setUserDetails(userData);
                console.log('User state rehydrated from backend:', userData);
                }),
                catchError(error => {
                console.error('Error fetching current user details:', error);
                return of(null);
                })
            );
        }
        return of(null);
    }

    // Call this after successful login to update the user details
    setUserDetails(user: any): void {
        this.userDetailsSubject.next(user);
        // You can also update individual observables if needed:
        if (user) {
            this.setUserFirstName(user['first_name']);
            this.setUserLastName(user['last_name']);
            this.setUserFullName(`${user['first_name']} ${user['last_name']}`);
            this.setUserPicture(user['picture']);
            this.setUserIsStaff(user.is_staff);
        }
    }
  
    setUserFirstName(firstName: string) {
        sessionStorage.setItem('firstname', firstName);
        this.userFirstName.next(firstName);
    }

    setUserLastName(lastName: string) {
        sessionStorage.setItem('lastname', lastName);
        this.userLastName.next(lastName);
    }

    setUserFullName(fullName: string) {
        sessionStorage.setItem('fullname', fullName);
        this.userFullName.next(fullName);
    }

    setUserIsStaff(isStaff: boolean): void {
        this.userIsStaff.next(isStaff);
    }

    setUserPicture(picture: string) {
        sessionStorage.setItem('picture', picture);
        this.userPicture.next(picture);
    }

    // Fake login method for development
    private fakeLogin() {
        const mockUser = {
            email: 'mockuser@example.com',
            given_name: 'Mock',
            family_name: 'User',
            picture: 'https://via.placeholder.com/150',
            locale: 'en'
        };

        sessionStorage.setItem('id_token_claims_obj', JSON.stringify(mockUser));
        sessionStorage.setItem(this.accessTokenKey, 'mock-access-token');
        sessionStorage.setItem(this.refreshTokenKey, 'mock-refresh-token');
        this.isAuthenticatedSubject.next(true);
        this.handleSuccessfulLogin();
    }
}
