// share-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent {
  shareUrl: string;

  constructor(
    public dialogRef: MatDialogRef<ShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { eventId: number },
    private clipboard: Clipboard
  ) {
    // Construct the share URL based on the event id.
    this.shareUrl = `${window.location.origin}/detail/${data.eventId}`;
  }

  copyUrl(): void {
    this.clipboard.copy(this.shareUrl);
    // Optionally, add a message or visual cue indicating success.
  }

  close(): void {
    this.dialogRef.close();
  }
}
