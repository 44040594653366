import { Component, OnInit } from '@angular/core';
import { MenuSubService } from 'src/app/_services/menu-sub.service';
import { ORGANIZATION_MENU_OPTIONS } from 'src/app/pages/organization/organization-menu/organization-config';
import { OrganizationService } from 'src/app/_services/organization.service';
import { Organization } from 'src/app/_interface/organization';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit {
  SUBJECT = 'organization';
  SUBJECT_PLURAL = 'organizations';
  organizations: Organization[] = [];
  // Define the columns to display in the table
  displayedColumns: string[] = ['name', 'email', 'phone_number', 'actions'];

  constructor(
    private menuSubService: MenuSubService,
    private organizationService: OrganizationService
  ) {}

  ngOnInit(): void {
    // Update the submenu options
    this.menuSubService.updateSubMenuOptions(ORGANIZATION_MENU_OPTIONS);

    // Fetch the list of organizations from the server
    this.organizationService.OrganizationListPublic().subscribe({
      next: (data: Organization[]) => {
        this.organizations = data;
      },
      error: (error) => {
        console.error('Error fetching organizations:', error);
      }
    });
  }
}
