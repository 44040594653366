// today/src/app/pages/event/event-routing.module.ts
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventAddComponent } from 'src/app/pages/event/event-add/event-add.component';
import { EventEditComponent } from './event-edit/event-edit.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { EventListComponent } from 'src/app/pages/event/event-list/event-list.component';
import { EventListPublicComponent } from './event-list-public/event-list-public.component';

const routes: Routes = [
    { path: 'add', component:  EventAddComponent },
    { path: 'admin-list', component:  EventListComponent },
    { path: 'edit/:id', component:  EventEditComponent },
    { path: 'detail/:id', component:  EventDetailComponent },
    { path: 'list', component:  EventListPublicComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EventsRoutingModule { }
