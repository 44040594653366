/* src/app/_services/event.service.ts */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Event } from 'src/app/_interface/event';
import { EventAdd } from 'src/app/_interface/event_add';
import { EventDetail } from 'src/app/_interface/event_detail';
import { environment } from 'src/environments/environment.prod';
import { ISO_8601 } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private baseUrl = `${environment.apiBaseUrl}event/`;

  constructor(private http: HttpClient) { }

  // Create a new event
    createEvent(event: EventDetail): Observable<EventDetail> {
        // Build a payload that only has the fields your EventSerializer is expecting:
        // scalars (name, description, date_start, date_end, is_online, status, etc.)
        // plus the *_id fields (address_id, category_id, organization_id, media_primary_id).
        const payload: any = {
            name: event.name,
            description: event.description,
            date_start: event.date_start,
            date_end: event.date_end,
            is_active: event.is_active,
            is_online: event.is_online,
            is_public: event.is_public,
            notes: event.notes,
            ordering: event.ordering,
            status: event.status,
            website: event.website
        };
      
        // For related fields, set only the *_id
        if (event.address) {
            payload.address_id = event.address;
        }
        if (event.category) {
            payload.category_id = event.category;
        }
        if (event.organization?.id) {
            payload.organization_id = event.organization.id;
        }
        if (event.media_primary?.id) {
            payload.media_primary_id = event.media_primary.id;
        }

        return this.http.post<EventDetail>(`${this.baseUrl}create/`, payload);
    }
  // Delete an event by UUID
  deleteEvent(eventId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}${eventId}/delete/`);
  }

  // Get details of an event by UUID
  getEventDetail(eventId: string): Observable<EventDetail> {
    return this.http.get<EventDetail>(`${this.baseUrl}${eventId}/`);
  }

  /**
   * Searches events within a specified range.
   *
   * This method sends a POST request to the range endpoint with a JSON payload
   * containing the starting date and a number of days to include. The backend
   * should then return only those events whose 'date_start' falls within the range
   * between the provided start date and the start date plus the given number of days.
   *
   * @param searchParams An object containing 'start_date' (in YYYY-MM-DD format)
   *                     and 'days' (an integer) that define the date range.
   * @returns Observable with an array of events that match the criteria.
   */
  getRange(searchParams: { start_date: string; days: number }): Observable<Event[]> {
    return this.http.post<Event[]>(`${this.baseUrl}range-search/`, searchParams);
  }
  
    // Update an event by UUID
    updateEvent(eventId: string, event: EventDetail): Observable<EventDetail> {
        // Build an object that includes only the needed scalars and the `_id` relationships
        const payload: any = {
            name: event.name,
            description: event.description,
            date_start: event.date_start,
            date_end: event.date_end,
            status: event.status,
            is_online: event.is_online,
            website: event.website
        };
      
        // If the user selected an address, pick out its ID
        if (event.address?.id) {
            payload.address_id = event.address.id;
        }
      
        // If you have a category, pick out its ID
        if (event.category?.id) {
            payload.category_id = event.category.id;
        }
      
        // Same idea for organization
        if (event.organization?.id) {
            payload.organization_id = event.organization.id;
        }
      
        // And the primary media
        if (event.media_primary?.id) {
            payload.media_primary_id = event.media_primary.id;
        }
      
        console.log('EVENT UPDATE payload: ', payload);
      
        // Now send only these fields to the server
        return this.http.put<EventDetail>(
            `${this.baseUrl}${eventId}/edit/`,
            payload
        );
    }
    
    // List all events
    listEvents(filters?: { [key: string]: string | number }): Observable<Event[]> {
        let params = new HttpParams();
        if (filters) {
            Object.keys(filters).forEach(key => {
                params = params.set(key, filters[key].toString());
            });
        }
        return this.http.get<Event[]>(this.baseUrl, { params });
    }
}