<div class="content-container">
    <h3>{{ SUBJECT_PLURAL }}</h3>
    <div *ngIf="isLoading">
        Loading organizations...
    </div>

    <div *ngIf="errorMessage">
        {{ errorMessage }}
    </div>

    <table *ngIf="!isLoading && filteredOrganizations.length" class="table">
        <thead>
            <tr>
                <th>
                    Name 
                    <input 
                      type="text" 
                      (input)="onFilterInput('name', $event)" 
                      placeholder="Filter Name"
                      class="filter">
                </th>
                <th>
                    Email 
                    <input 
                      type="text" 
                      (input)="onFilterInput('email', $event)" 
                      placeholder="Filter Email"
                      class="filter">
                </th>
                <th>
                    Phone Number 
                    <input 
                      type="text" 
                      (input)="onFilterInput('phone_number', $event)" 
                      placeholder="Filter Phone Number"
                      class="filter">
                </th>
                <th>
                    Actions
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let organization of pagedOrganizations">
                <td>
                    <a [routerLink]="['/organization/edit-staff', organization.id]">{{ organization.name }}</a>
                </td>   
                <td>{{ organization.email }}</td>
                <td>{{ organization.phone_number }}</td>
                <td>
                    <button *ngIf="organization.website"
                        id="open-website"
                        mat-icon-button
                        (click)="openWebsite(organization.website)"
                        [matTooltip]="'go to ' + organization.website"
                        color="primary"
                        aria-label="Go to website">
                            <mat-icon>language</mat-icon>
                    </button>

                    <button
                        id="delete-organization"
                        mat-icon-button
                        color="warn"
                        (click)="deleteOrganization(organization.id)"
                        aria-label="Delete Organization">
                            <mat-icon>delete</mat-icon>
                    </button>          
                </td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="!isLoading && filteredOrganizations.length === 0">
        No organizations found.
    </div>

    <!-- PrimeNG paginator -->
    <p-paginator 
        [first]="first" 
        [rows]="rows" 
        [totalRecords]="filteredOrganizations.length" 
        (onPageChange)="onPageChange($event)">
    </p-paginator>
</div>
