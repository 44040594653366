/* today/src/app/_services/address.service.ts */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Address } from 'src/app/_interface/address';
import { AddressAdd } from 'src/app/_interface/address_add';
import { AddressDetail } from 'src/app/_interface/address_detail';
import { environment } from 'src/environments/environment.prod';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    private baseUrl = `${environment.apiBaseUrl}address/`;

    constructor(private http: HttpClient) {}

    // today/src/app/_services/address.service.ts
    AddressCreate(address: AddressAdd): Observable<AddressDetail> {
        console.log(JSON.stringify(address));
        return this.http.post<AddressDetail>(`${this.baseUrl}create/`, address);
    }

    // Delete an address by UUID
    AddressDelete(addressId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}${addressId}/delete/`);
    }

    // Get details of an address by UUID
    AddressDetail(addressId: string): Observable<AddressDetail> {
        return this.http.get<AddressDetail>(`${this.baseUrl}${addressId}/`);
    }

    // Update an address by UUID
    AddressUpdate(addressId: string, address: AddressDetail): Observable<AddressDetail> {
        return this.http.put<AddressDetail>(`${this.baseUrl}${addressId}/edit/`, address);
    }

    // List all address (again, if your API returns an array of AddressDetail)
    AddressList(): Observable<Address[]> {
        console.log(this.baseUrl);
        return this.http.get<Address[]>(this.baseUrl);
    }
}
