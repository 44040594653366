/* today/src/app/pages/address/address-edit/address-edit.component.ts */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressService } from 'src/app/_services/address.service';
import { AddressDetail } from 'src/app/_interface/address_detail';
import { City } from 'src/app/_interface/city';
import { County } from 'src/app/_interface/county';
import { State } from 'src/app/_interface/state';
import { Country } from 'src/app/_interface/country';
import { ZipCode } from 'src/app/_interface/zipcode';

@Component({
    selector: 'app-address-edit',
    templateUrl: './address-edit.component.html',
    styleUrls: ['./address-edit.component.css']
})
export class AddressEditComponent implements OnInit {
    addressId: string = '';
    address?: AddressDetail;

    constructor(
        private addressService: AddressService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.addressId = params.get('id') || '';
            if (this.addressId) {
                this.addressService.AddressDetail(this.addressId).subscribe({
                    next: (data: AddressDetail) => {
                        // Transform incoming data to ensure that the foreign key fields are objects
                        // and that postal_code is in the expected format.
                        this.address = {
                            ...data,
                            city: this.ensureObject<City>(data.city),
                            county: this.ensureObject<County>(data.county),
                            state: this.ensureObject<State>(data.state),
                            // Assuming country is always returned as an object.
                            country: data.country,
                            postal_code: typeof data.postal_code === 'string'
                                ? { zip_code: data.postal_code } as ZipCode
                                : data.postal_code || { zip_code: '' }
                        };
                    },
                    error: (err) => {
                        console.error('Error fetching address details:', err);
                    }
                });
            }
        });
    }

    onSubmit(): void {
        if (!this.address) return;

        // Create a payload that ensures foreign keys are sent as string IDs,
        // and that postal_code is sent as a zip code string.
        const payload: any = {
            ...this.address,
            city: this.address.city != null 
              ? (typeof this.address.city === 'object' ? this.address.city.id : this.address.city)
              : null,
            county: this.address.county != null && typeof this.address.county === 'object'
              ? this.address.county.id 
              : this.address.county,
            state: this.address.state != null && typeof this.address.state === 'object'
              ? this.address.state.id 
              : this.address.state,
            country: this.address.country != null && typeof this.address.country === 'object'
              ? this.address.country.id 
              : this.address.country,
            postal_code: (this.address.postal_code && typeof this.address.postal_code === 'object')
              ? this.address.postal_code.zip_code
              : this.address.postal_code
        };

        this.addressService.AddressUpdate(this.addressId, payload).subscribe({
            next: (updatedAddress: AddressDetail) => {
                console.log('Address updated successfully:', updatedAddress);
                this.router.navigate(['address/list']);
            },
            error: (err) => {
                console.error('Error updating address:', err);
            }
        });
    }

    // Helper method to ensure a field is treated as an object.
    private ensureObject<T>(data: T | string | null): T {
        if (!data) {
            return {} as T;
        }
        if (typeof data === 'string') {
            return { id: data, name: '' } as unknown as T;
        }
        return data as T;
    }

    // Getter and Setter for zipCode (for postal_code)
    get zipCode(): string {
        return this.address?.postal_code?.zip_code || '';
    }
    set zipCode(value: string) {
        if (this.address) {
            if (!this.address.postal_code) {
                this.address.postal_code = { zip_code: value } as ZipCode;
            } else {
                this.address.postal_code.zip_code = value;
            }
        }
    }

    // Getters for displaying location names (used in your template)
    get cityName(): string {
        return this.address?.city != null && typeof this.address.city === 'object'
        ? this.address.city.name
        : '';
    }
    get countyName(): string {
        return typeof this.address?.county === 'object' ? this.address.county.name : '';
    }
    get stateName(): string {
        const state = this.address?.state;
        return state && typeof state === 'object' ? state.name : '';
    }
    get countryName(): string {
        return typeof this.address?.country === 'object' ? this.address.country.name : '';
    }
}
