import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from 'src/app/_interface/category';
import { environment } from 'src/environments/environment.prod';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    private baseUrl = `${environment.apiBaseUrl}category/`;

    constructor(private http: HttpClient) { }

    // List all categories
    listCategories(categoryType?: string): Observable<Category[]> {
      let params = new HttpParams();
      if (categoryType) {
          params = params.set('type', categoryType);
      }
      return this.http.get<Category[]>(this.baseUrl, { params });
  }


    // Get details of a category by ID
    getCategoryDetail(categoryId: string): Observable<Category> {
        return this.http.get<Category>(`${this.baseUrl}${categoryId}/`);
    }

    // Create a new category
    createCategory(category: Category): Observable<Category> {
        return this.http.post<Category>(`${this.baseUrl}create/`, category);
    }

    // Update an existing category by ID
    updateCategory(categoryId: string, category: Category): Observable<Category> {
        return this.http.put<Category>(`${this.baseUrl}${categoryId}/edit/`, category);
    }

    // Delete a category by ID
    deleteCategory(categoryId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}${categoryId}/delete/`);
    }
}
