<div class="content-container">
    <p>A list of local events including both online and offline events.</p>
  
  <!-- Add a scrollable wrapper -->
  <div class="table-container">
    <table mat-table [dataSource]="events" class="mat-elevation-z8">
      <!-- Event Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Event Name </th>
        <td mat-cell *matCellDef="let event"> {{ event.name }} </td>
      </ng-container>
  
      <!-- Start Date Column -->
      <ng-container matColumnDef="date_start">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell *matCellDef="let event">
          {{ event.date_start | date:'medium' }}
        </td>
      </ng-container>
  
      <!-- End Date Column -->
      <ng-container matColumnDef="date_end">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell *matCellDef="let event">
          {{ event.date_end | date:'medium' }}
        </td>
      </ng-container>
  
      <!-- Online Column -->
      <ng-container matColumnDef="is_online">
        <th mat-header-cell *matHeaderCellDef> Online? </th>
        <td mat-cell *matCellDef="let event">
          {{ event.is_online ? 'Yes' : 'No' }}
        </td>
      </ng-container>
  
      <!-- Actions Column (Edit and Delete) -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let event">
          <!-- Edit Button -->
          <button
              mat-icon-button
              [routerLink]="['/event/edit', event.id]"
              aria-label="Edit Event"
              class="button-action">
              <mat-icon>edit</mat-icon>
          </button>
          <!-- Delete Button -->
          <button
              mat-icon-button
              (click)="deleteEvent(event.id)"
              aria-label="Delete Event"
              class="button-action">
              <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
  
      <!-- Header and Row Declarations -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
  