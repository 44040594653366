// today/src/app/pages/address/address-menu/address-menu.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-address-menu',
  templateUrl: './address-menu.component.html',
  styleUrl: './address-menu.component.css'
})
export class AddressMenuComponent {

}
