<!-- src/app/pages/event/event-list-public/event-list-public.component.html -->
<div class="event-list-container">
  <div class="column-left">
    <ng-container *ngIf="rangeEvents">
      <mat-card *ngFor="let event of rangeEvents" class="event-card">
        <!-- Circle date badge -->
        <div class="date-circle">
          <span class="month">{{ event.date_start | date:'MMM' }}</span>
          <span class="day">{{ event.date_start | date:'d' }}</span>
        </div>
  
        <mat-card-header>
          <mat-card-title-group>
            <mat-card-title>
              <span class="event-title">{{ event.name }}</span>
            </mat-card-title>
            <mat-card-subtitle>
              <span class="event-title-sub">{{ event.category?.name }}</span>
            </mat-card-subtitle>
          </mat-card-title-group>
        </mat-card-header>
  
        <mat-card-content>
          <!-- Removed the inline style="width: 500px;" so CSS can set width to 100% -->
          <img
            *ngIf="event.media_primary?.file_url"
            mat-card-image
            [src]="event.media_primary?.file_url"
          >
          <br>
          <div class="event-description">
            <ng-container *ngIf="!event.isExpanded; else fullDescription">
              {{ event.description | stripHtml | slice:0:75 }}
              <span *ngIf="(event.description | stripHtml).length > 75">
                ... <a href="javascript:void(0)" (click)="event.isExpanded = true">read more</a>
              </span>
            </ng-container>
            <ng-template #fullDescription>
              <span [innerHTML]="event.description"></span>
              <a href="javascript:void(0)" (click)="event.isExpanded = false">read less</a>
            </ng-template>
          </div>
        </mat-card-content>
  
        <mat-card-actions class="responsive-actions">
          <button mat-button (click)="openShareDialog(event)">
            <mat-icon>share</mat-icon> share
          </button>
          <div class="address-container">
            <button mat-button (click)="openInGoogleMaps(event)">
              <mat-icon>location_on</mat-icon>
              <span>
                {{ event.address?.street }}, {{ getCityName(event.address?.city) }}, {{ getStateCode(event.address?.state) }}
              </span>
            </button>
          </div>
          <!-- Website Button: Only displayed if event.website is provided -->
          <button mat-button *ngIf="event.website" (click)="openWebsite(event.website)">
            <mat-icon>public</mat-icon>{{ event.website }}
          </button>
        </mat-card-actions>

        
      </mat-card>
    </ng-container>
  </div>  
  <div class="column-right">
      <p-calendar [(ngModel)]="selectedDate" [inline]="true" (onSelect)="onDateSelect($event)"></p-calendar>
  </div>
</div>
  
<p-dialog 
  header="Share Event"
  [(visible)]="displayShareDialog" 
  [modal]="true" 
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{'min-width': '250px'}"
  [baseZIndex]="10000"
>
  <p>Copy the link below to share this event:</p>
  
  <div class="share-dialog-content">
    <!-- If you're using the Angular CDK Clipboard -->
    <input #urlInput type="text" [value]="shareUrl" readonly>
  
    <button mat-button (click)="copyUrl(urlInput.value)">
      Copy URL
    </button>
  </div>
</p-dialog>
