import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrganizationAddComponent } from './organization-add/organization-add.component';
import { OrganizationEditStaffComponent } from './organization-edit-staff/organization-edit-staff.component';
import { OrganizationListStaffComponent } from './organization-list-staff/organization-list-staff.component';
import { OrganizationListComponent } from './organization-list/organization-list.component';

const routes: Routes = [
    { path: 'add', component: OrganizationAddComponent },
    { path: 'edit-staff/:id', component: OrganizationEditStaffComponent },
    { path: 'list', component:  OrganizationListComponent },
    { path: 'list-staff', component:  OrganizationListStaffComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OrganizationRoutingModule { }
