<div *ngIf="false">today/src/app/pages/address/address-add/address-add.component.html</div>
<div class="content-container">
    <h2>{{ACTION}} {{SUBJECT}}</h2>

    <form (ngSubmit)="onSubmit()">
        <fieldset>
            <legend>Basic Information</legend>
            <!-- Street -->
            <div>
                <label for="street">Street:</label>
                <input id="street" type="text" name="street" [(ngModel)]="address.street" required aria-required="true" />
            </div>
            <!-- Postal Code -->
            <div>
                <label for="postal_code">Postal Code:</label>
                <input
                    id="postal_code"
                    type="text"
                    name="postal_code"
                    [(ngModel)]="address.postal_code"
                    required
                    aria-label="Postal Code"
                    (blur)="lookupZipCode()"
                />
            </div>
        </fieldset>

        <fieldset>
            <legend>Location</legend>

            <div>
                <label for="city">City:</label>
                <select id="city" name="city" [(ngModel)]="address.city_id" disabled>
                    <option *ngIf="address.city_id" [value]="address.city_id">{{ cityName }}</option>
                </select>
            </div>

            <div>
                <label for="county">County:</label>
                <select id="county" name="county" [(ngModel)]="address.county_id" disabled>
                    <option *ngIf="address.county_id" [value]="address.county_id">{{ countyName }}</option>
                </select>
            </div>

            <div>
                <label for="state">State:</label>
                <select id="state" name="state" [(ngModel)]="address.state_id" disabled>
                    <option *ngIf="address.state_id" [value]="address.state_id">{{ stateName }}</option>
                </select>
            </div>

            <div>
                <label for="country">Country:</label>
                <select id="country" name="country" [(ngModel)]="address.country_id" disabled>
                    <option *ngIf="address.country_id" [value]="address.country_id">{{ countryName }}</option>
                </select>
            </div>
        </fieldset>

        <fieldset>
            <!-- Name -->
            <legend>Description</legend>
            <div>
                <label for="name">Name:</label>
                <input id="name" type="text" name="name" [(ngModel)]="address.name" required aria-required="true" />
            </div>
            <div>
                <label for="description">Description:</label>
                <textarea id="description" name="description" [(ngModel)]="address.description"></textarea>
            </div>
        </fieldset>

        <fieldset>
            <legend>Address Settings</legend>
            <!-- Status -->
            <div>
                <label for="status">Status:</label>
                <select id="status" name="status" [(ngModel)]="address.status" aria-label="Address Status">
                    <option value="approved">Approved</option>
                    <option value="draft">Draft</option>
                    <option value="pending">Pending</option>
                </select>
            </div>
            <!-- Active & Public Settings -->
            <div>
                <input id="is_active" type="checkbox" name="is_active" [(ngModel)]="address.is_active" />
                <label for="is_active">Active</label>
            </div>
            <div>
                <input id="is_public" type="checkbox" name="is_public" [(ngModel)]="address.is_public" />
                <label for="is_public">Public</label>
            </div>
        </fieldset>

        <!-- Submit Button -->
        <button type="submit">Save</button>
    </form>
</div>
