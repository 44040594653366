import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

import { AddressAddComponent } from 'src/app/pages/address/address-add/address-add.component';
import { AddressEditComponent } from 'src/app/pages/address/address-edit/address-edit.component';
import { AddressListComponent } from 'src/app/pages/address/address-list/address-list.component';
import { AddressMenuComponent } from 'src/app/pages/address/address-menu/address-menu.component';
import { AddressRoutingModule } from 'src/app/pages/address/address-routing.module';

@NgModule({
    declarations: [
        AddressAddComponent,
        AddressEditComponent,
        AddressListComponent,
        AddressMenuComponent,
    ],
    imports: [
        CommonModule,
        AddressRoutingModule,
        FormsModule,
        MatIconModule,
        MatTableModule
    ],
    exports: [
        AddressAddComponent,
        AddressEditComponent,
        AddressListComponent,
        AddressMenuComponent,
    ]
})
export class AddressModule { }
